import React from 'react';
import Chart from 'react-google-charts';


class StatisticsByPercentage extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            colors: null,
        };
    }


    render(){
        const escuchas = [];
        const porcentajes = [];
        const rows = [];
        for (const [index, value] of this.props.dataStatistics.entries()){

            const date = new Date(value.register_at); 
            let dateString;
            let fullDateString; 
            if(this.props.dateRangeType === 'byHour'){
                dateString = date.getUTCHours().toString();
                dateString = (dateString+":00")
                fullDateString = date.getUTCDate().toString()  + '/' +(date.getUTCMonth()+1).toString() + '/' +  date.getFullYear().toString()+' '+ dateString;

            }else{
                dateString = date.getUTCDate().toString()  + '/' +(date.getUTCMonth()+1).toString() + '/' +  date.getFullYear().toString();
                fullDateString = dateString;
            }
            escuchas.push(value.number_listeners);
            if(!porcentajes.includes(value.percentage)){
                porcentajes.push(value.percentage)
            }


            rows.unshift(
                <tr key={index}>
                    <th>{fullDateString}</th>
                    <th>{value.percentage}</th>
                    <th>{value.number_users}</th>
                    <th>{value.number_listeners}</th>
                </tr>
            )

        }
        let dataPorcentaje = []
        let dataParaMostrar='';
        let dataMostrarGoogle = [['Porcentage', 'Escuchas']];
        if(this.props.percentage === ''){

            for (let index = 0; index < porcentajes.length; index++) {
                dataPorcentaje.push(0);
                
            }
            for (const [, value] of this.props.dataStatistics.entries()){
                dataPorcentaje[porcentajes.indexOf(value.percentage)] = dataPorcentaje[porcentajes.indexOf(value.percentage)] + value.number_listeners;
            }
            dataParaMostrar = porcentajes;
        }
        
        for (let index = 0; index < dataPorcentaje.length; index++) {
            const porcentaje = dataPorcentaje[index];
            const data = dataParaMostrar[index];
            dataMostrarGoogle.push([data, porcentaje]);
        }
        console.log(dataMostrarGoogle);
        const isData = this.props.dataStatistics.length >0;
        const isSelectedPercentage = this.props.isSelectedPercentage;

        return(
            <div>
                {isData ?(

                    <div>
                        <div className="row justify-content-center align-items-center py-4">
                            {!isSelectedPercentage &&
                                <div className="col-12 p-0">

                                <Chart
                                    width={'105%'}
                                    height={'400px'}
                                    chartType="PieChart"
                                    loader={<div>Cargando gráfico</div>}
                                    data={dataMostrarGoogle}
                                    options={{
                                        title: 'Estadísticas por porcentaje',
                                        backgroundColor: '#f3f2f2',
                                    }}
                                    rootProps={{ 'data-testid': '1' }}
                                />

                                </div> 
                                }
                        </div>
                        <div className="table-responsive-md">

                            <table id="download-this" className="table table-striped">
                                    <thead className="table-head">
                                        <tr>
                                            <th>Fecha</th>
                                            <th>Porcentaje</th>
                                            <th># Usuarios</th>
                                            <th># Escuchas</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows}
                                    </tbody>
                            </table>
                        </div>
                    </div>
                ): (
                    <div className="row justify-content-center align-items-center min-size">
                        <div className="h1">
                            No hay escuchas disponibles
                        </div>
                    </div>
                )}
            </div>
        );
    }


}

export default StatisticsByPercentage;