import React from 'react'
import { Link } from 'react-router-dom';
import './terms.css';
import Footer from '../landing/footer';
export const Terms = () => {
  return (
    <div className='App terms__app'>
       <div>
                <div className="site-mobile-menu site-navbar-target">
                    <div className="site-mobile-menu-header">
                        <div className="site-mobile-menu-close mt-3">
                            <span className="icofont-close js-menu-toggle"></span>
                        </div>
                    </div>
                    <div className="site-mobile-menu-body"></div>
                </div>
                <header className="site-navbar  js-sticky-header site-navbar-target terms__color-header" role="banner">

                    <div className="container">
                        <div className="row align-items-center">

                            <div className="col-6 col-lg-2">
                                <h1 className="mb-0 site-logo"><a href="/" className="mb-0"><img alt="Swapper Logo" src="img/swapper/swapper-logo-1.svg"/></a></h1>
                            </div>

                            <div className="col-12 col-md-10 d-none d-lg-block">
                                <nav className="site-navigation position-relative text-right" role="navigation">

                                    <ul className="site-menu main-menu js-clone-nav mr-auto d-none d-lg-block">
                                        <li><Link className="nav-link" to={"/"}>Página inicial</Link></li>
                                        <li><Link className="nav-link" to={"/login"}>Iniciar sesión</Link></li>
                                    </ul>
                                </nav>
                            </div>

                            <div className="col-6 d-inline-block d-lg-none ml-md-0 py-3">

                                <a href="#" className="burger site-menu-toggle js-menu-toggle" data-toggle="collapse" data-target="#main-navbar">
                                    <span></span>
                                </a>
                            </div>

                        </div>
                    </div>

                </header>
            </div>
            <div className='container terms__container'>
                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-10'>
                        <h3 className='terms__h3'>Condiciones generales</h3>
                        <p className='terms__p'>Al acceder y realizar un pedido a Swapper, usted confirma que está de acuerdo con las condiciones de servicio contenidas en los Términos y Condiciones que se detallan a continuación y que está obligado a cumplirlas. Estos términos se aplican a todo el sitio web y a cualquier correo electrónico u otro tipo de comunicación entre usted y Swapper.<br/> <br />
                            Bajo ninguna circunstancia el equipo de Swapper será responsable de ningún daño directo, indirecto, especial, incidental o consecuente, incluyendo, pero sin limitarse a, la pérdida de datos o beneficios, que surja del uso, o la incapacidad de usar, los materiales de este sitio, incluso si el equipo de Swapper o un representante autorizado ha sido advertido de la posibilidad de tales daños. Si el uso de los materiales de este sitio da lugar a la necesidad de mantenimiento, reparación o corrección de equipos o datos, usted asume los costes correspondientes.<br /> <br/>
                            Swapper no se hace responsable de ningún resultado que pueda producirse durante el uso de nuestros recursos. Nos reservamos el derecho de cambiar los precios y revisar la política de uso de los recursos en cualquier momento.
                        </p>
                    </div>
                </div>
                <br />
                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-10'>

                    <h3 className='terms__h3'>Licencia</h3>
                    <p className='terms__p'>Swapper le concede una licencia revocable, no exclusiva, intransferible y limitada para descargar, instalar y utilizar el sitio web/la aplicación estrictamente de acuerdo con los términos de este Acuerdo.<br /><br />
                        Estos Términos y Condiciones son un contrato entre usted y Swapper (referido en estos Términos y Condiciones como "Swapper", "nosotros", "nos" o "nuestro"), el proveedor del sitio web de Swapper y de los servicios accesibles desde el sitio web de Swapper (que se denominan colectivamente en estos Términos y Condiciones como el "Servicio de Swapper").<br /><br />
                        Usted acepta someterse a estos Términos y Condiciones. Si no está de acuerdo con estos Términos y Condiciones, no utilice el Servicio Swapper. En estos Términos y Condiciones, "usted" se refiere tanto a usted como individuo como a la entidad que representa. Si viola cualquiera de estos Términos y Condiciones, nos reservamos el derecho de cancelar su cuenta o bloquear el acceso a su cuenta sin previo aviso.
                    </p>
                    </div>
                </div>
                <br />
                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-10'>

                        <h3 className='terms__h3'>Definiciones y términos clave </h3>
                        <p className='terms__p'>Para ayudar a explicar las cosas lo más claramente posible en estos Términos y Condiciones, cada vez que se hace referencia a cualquiera de estos términos, se definen estrictamente como: <br />
                        </p>
                        <ul className='terms__ul'>
                            <li className='terms__il'>
                                Cookie: pequeña cantidad de datos generada por un sitio web y guardada por su navegador. Se utiliza para identificar su navegador, proporcionar análisis, recordar información sobre usted, como su preferencia de idioma o información de inicio de sesión. 
                            </li>
                            <li className='terms__il'>
                                Empresa: cuando estos términos mencionan "Empresa", "nosotros", "nos" o "nuestro", se refiere a Swapper, que es responsable de su información según estos Términos y Condiciones.
                            </li>
                            <li className='terms__il'>
                                País: donde Swapper o los propietarios/fundadores de Swapper tienen su sede, en este caso es Venezuela 
                            </li>
                            <li className='terms__il'>
                                Dispositivo: cualquier dispositivo conectado a Internet, como un teléfono, una tableta, un ordenador o cualquier otro dispositivo que pueda utilizarse para visitar Swapper y utilizar los servicios. 
                            </li>
                            <li className='terms__il'>
                                Servicio: se refiere al servicio prestado por Swapper tal y como se describe en los términos relativos (si están disponibles) y en esta plataforma. 
                            </li>
                            <li className='terms__il'>
                                Servicio de terceros: se refiere a los anunciantes, patrocinadores de concursos, socios promocionales y de marketing, y otros que proporcionan nuestro contenido o cuyos productos o servicios creemos que pueden interesarle. 
                            </li>
                            <li className='terms__il'>
                                Sitio web: El sitio de Swapper, al que se puede acceder a través de esta URL: www.swapper.media 
                            </li>
                            <li className='terms__il'>
                                Usted: persona o entidad que se ha registrado en Swapper para utilizar los Servicios.
                            </li>
                        </ul>
                    </div>
                </div>
                <br />
                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-10'>

                    <h3 className='terms__h3'>Su consentimiento</h3>
                    <p className='terms__p'>Hemos actualizado nuestros Términos y Condiciones para ofrecerle una total transparencia sobre lo que se establece cuando visita nuestro sitio y cómo se utiliza. Al utilizar nuestro sitio web/aplicación, registrar una cuenta o realizar una compra, usted acepta nuestros Términos y Condiciones. <br /> <br />
                    </p>
                    </div>
                </div>
                <br />
                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-10'>

                    <h3 className='terms__h3'>Restricciones</h3>
                    <p className='terms__p'>Usted se compromete a no, y no permitirá a otros: <br /> <br />
                        </p>
                        <ul className='terms__ul'>
                            <li className='terms__li'>
                            Otorgar licencias, vender, alquilar, arrendar, ceder, distribuir, transmitir, alojar, subcontratar, divulgar o explotar comercialmente de otro modo el sitio web/la aplicación o poner la plataforma a disposición de terceros.
                            </li>
                            <li className='terms__li'>
                            Modificar, hacer obras derivadas, desensamblar, descifrar, compilar o aplicar ingeniería inversa a cualquier parte del sitio web o la aplicación. 
                            </li>
                            <li className='terms__li'>
                            Eliminar, alterar u ocultar cualquier aviso de propiedad (incluyendo cualquier aviso de derechos de autor o marca registrada) de Swapper o sus afiliados, socios, proveedores o los licenciantes del sitio web / aplicación.
                            </li>
                        </ul> </div>
                </div>
                <br />
                
                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-10'>

                    <h3 className='terms__h3'>Sus sugerencias</h3>
                    <p className='terms__p'>
                        Cualquier opinión, comentario, idea, mejora o sugerencia (colectivamente, "Sugerencias") proporcionada por usted a Swapper con respecto al sitio web/la aplicación será propiedad única y exclusiva de Swapper.<br/><br/>
                        Swapper será libre de utilizar, copiar, modificar, publicar o redistribuir las Sugerencias para cualquier propósito y de cualquier manera sin ningún crédito o compensación para usted.
                    </p>  
                    </div>
                </div>
                <br />
                
                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-10'>

                    <h3 className='terms__h3'>Enlaces a otros sitios web</h3>
                    <p className='terms__p'>
                        Las presentes Condiciones Generales se aplican únicamente a los Servicios. Los Servicios pueden contener enlaces a otros sitios web no operados ni controlados por Swapper. No somos responsables del contenido, la exactitud o las opiniones expresadas en dichos sitios web, y no investigamos, supervisamos o comprobamos la exactitud o integridad de los mismos. Recuerde que cuando utilice un enlace para ir desde los Servicios a otro sitio web, nuestros Términos y Condiciones dejarán de estar en vigor. Su navegación e interacción en cualquier otro sitio web, incluidos los que tienen un enlace en nuestra plataforma, está sujeta a las propias normas y políticas de ese sitio web. Estos terceros pueden utilizar sus propias cookies u otros métodos para recopilar información sobre usted.
                    </p>  
                    </div>
                </div>
                <br />
                

                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-10'>

                    <h3 className='terms__h3'>Cookies</h3>
                    <p className='terms__p'>
                    Swapper utiliza "cookies" para identificar las áreas de nuestro sitio web que usted ha visitado. Una "cookie" es un pequeño fragmento de datos almacenado en su ordenador o dispositivo móvil por su navegador web. Utilizamos las cookies para mejorar el rendimiento y la funcionalidad de nuestro sitio web/aplicación, pero su uso no es esencial. Sin embargo, sin estas cookies, algunas funciones, como los vídeos, podrían dejar de estar disponibles o se le pediría que introdujera sus datos de acceso cada vez que visitara el sitio web o la aplicación, ya que no podríamos recordar que se había conectado previamente. La mayoría de los navegadores pueden desactivar el uso de cookies. Sin embargo, si desactiva las cookies, es posible que no pueda acceder a las funciones de nuestro sitio web correctamente o en absoluto. Nunca introducimos información personal en las cookies.  
                    </p>
                    </div>
                </div>
                <br />
                
                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-10'>

                    <h3 className='terms__h3'>Cambios en nuestros términos y condiciones</h3>
                    <p className='terms__p'>
                    Usted reconoce y acepta que Swapper puede dejar de proporcionarle (de forma permanente o temporal) el Servicio (o cualquier característica del mismo) a usted o a los usuarios en general a la sola discreción de Swapper, sin previo aviso. Usted puede dejar de utilizar el Servicio en cualquier momento. No es necesario que informe específicamente a Swapper cuando deje de utilizar el Servicio. Usted reconoce y acepta que si Swapper desactiva el acceso a su cuenta, se le puede impedir el acceso al Servicio, a los detalles de su cuenta o a cualquier archivo u otro material que esté contenido en su cuenta.<br /> <br />
                    Si decidimos cambiar nuestros Términos y Condiciones, publicaremos dichos cambios en esta página, y/o actualizaremos la fecha de modificación de los Términos y Condiciones que aparece a continuación.
                    </p>  
                    </div>
                </div>
                <br />
                
                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-10'>

                    <h3 className='terms__h3'>Modificaciones en nuestro sitio web/app</h3>
                    <p className='terms__p'>
                        Swapper se reserva el derecho de modificar, suspender o interrumpir, temporal o permanentemente, el sitio web/aplicación o cualquier servicio al que se conecte, con o sin previo aviso y sin responsabilidad para usted.
                    </p>  
                    </div>
                </div>
                <br />
                
                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-10'>

                    <h3 className='terms__h3'>Actualizaciones de nuestro sitio web/app</h3>
                    <p className='terms__p'>
                    Swapper puede proporcionar de vez en cuando mejoras a las características/funcionalidad del sitio web/aplicación, que pueden incluir parches, correcciones de errores, actualizaciones, mejoras y otras modificaciones ("Actualizaciones"). <br /> <br />
                    Las actualizaciones pueden modificar o eliminar ciertas características y/o funcionalidades del sitio web/la aplicación. Usted acepta que Swapper no tiene ninguna obligación de (i) proporcionar alguna Actualización, o (ii) continuar proporcionando o habilitando cualquier característica y/o funcionalidad particular del sitio web/aplicación para usted. <br /> <br />
                    Asimismo, acepta que todas las Actualizaciones (i) se considerarán parte integrante del sitio web/aplicación, y (ii) estarán sujetas a los términos y condiciones de este Acuerdo.
                    </p>  
                    </div>
                </div>
                <br />
                
                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-10'>

                    <h3 className='terms__h3'>Servicios de terceros</h3>
                    <p className='terms__p'>
                    Podemos mostrar, incluir o poner a disposición contenidos de terceros (incluyendo datos, información, aplicaciones y otros servicios de productos) o proporcionar enlaces a sitios web o servicios de terceros ("Servicios de terceros"). <br /> <br />
                    Usted reconoce y acepta que Swapper no será responsable de los Servicios de Terceros, incluyendo su exactitud, integridad, puntualidad, validez, cumplimiento de los derechos de autor, legalidad, decencia, calidad o cualquier otro aspecto de los mismos. Swapper no asume ni tendrá ninguna responsabilidad ante usted o cualquier otra persona o entidad por los Servicios de Terceros. <br /> <br />
                    Los Servicios de Terceros y los enlaces a los mismos se facilitan únicamente para su comodidad y usted accede a ellos y los utiliza bajo su propia responsabilidad y sujeto a los términos y condiciones de dichos terceros.
                    </p>  
                    </div>
                </div>
                <br />
                
                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-10'>

                    <h3 className='terms__h3'>Duración y terminación</h3>
                    <p className='terms__p'>
                    El presente Contrato permanecerá en vigor hasta que usted o Swapper lo rescindan. <br /> <br />
                    Swapper podrá, a su entera discreción, en cualquier momento y por cualquier motivo o sin él, suspender o rescindir el presente Acuerdo con o sin previo aviso. <br /> <br />
                    El presente Contrato se rescindirá inmediatamente, sin previo aviso de Swapper, en caso de que usted no cumpla con alguna de las disposiciones del mismo. También puede rescindir el presente Contrato borrando el sitio web/la aplicación y todas las copias del mismo de su ordenador. <br /> <br />
                    Tras la rescisión de este Contrato, deberá dejar de utilizar el sitio web/aplicación y eliminar todas las copias del sitio web/aplicación de su ordenador.<br /> <br />
                    La terminación de este Acuerdo no limitará ninguno de los derechos o recursos de Swapper en derecho o en equidad en caso de incumplimiento por su parte (durante la vigencia de este Acuerdo) de cualquiera de sus obligaciones en virtud del presente Acuerdo.
                    </p>  
                    </div>
                </div>
                <br />
                
                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-10'>

                    <h3 className='terms__h3'>Aviso de infracción de derechos de autor</h3>
                    <p className='terms__p'>
                        Si usted es propietario de los derechos de autor o agente de dicho propietario y cree que algún material de nuestro sitio web o aplicación constituye una infracción de sus derechos de autor, póngase en contacto con nosotros indicando la siguiente información (a) una firma física o electrónica del propietario de los derechos de autor o de una persona autorizada para actuar en su nombre; (b) la identificación del material que se reclama como infractor; (c) su información de contacto, incluyendo su dirección, número de teléfono y un correo electrónico; (d) una declaración suya en la que afirme que cree de buena fe que el uso del material no está autorizado por los propietarios de los derechos de autor; y (e) una declaración en la que afirme que la información contenida en la notificación es exacta y que, bajo pena de perjurio, está autorizado a actuar en nombre del propietario.
                    </p>  
                    </div>
                </div>
                <br />
                
                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-10'>

                    <h3 className='terms__h3'>Indemnización</h3>
                    <p className='terms__p'>
                        Usted se compromete a indemnizar y a mantener indemne a Swapper y a sus empresas matrices, subsidiarias, afiliadas, directivos, empleados, agentes, socios y licenciantes (si los hubiera) de cualquier reclamación o demanda, incluidos los honorarios razonables de los abogados, debida o derivada de su (a) el uso del sitio web/la aplicación; (b) la violación de este Acuerdo o de cualquier ley o reglamento; o (c) la violación de cualquier derecho de un tercero. 
                    </p>  
                    </div>
                </div>
                <br />
                
                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-10'>

                    <h3 className='terms__h3'>Sin garantías</h3>
                    <p className='terms__p'>
                    El sitio web/la aplicación se le proporciona "TAL CUAL" y "SEGÚN DISPONIBILIDAD" y con todos los fallos y defectos sin garantía de ningún tipo. En la medida máxima permitida por la ley aplicable, Swapper, en su propio nombre y en el de sus afiliados y sus respectivos licenciadores y proveedores de servicios, renuncia expresamente a todas las garantías, ya sean expresas, implícitas, estatutarias o de otro tipo, con respecto al sitio web/la aplicación, incluyendo todas las garantías implícitas de comerciabilidad, idoneidad para un propósito particular, título y no infracción, y las garantías que puedan surgir del curso de las negociaciones, el curso de la ejecución, el uso o la práctica comercial. Sin limitación de lo anterior, Swapper no ofrece ninguna garantía o compromiso, y no hace ninguna declaración de ningún tipo de que el sitio web/la aplicación cumplirá con sus requisitos, logrará los resultados previstos, será compatible o funcionará con cualquier otro software, sitio web/ aplicación, sistemas o servicios, funcionará sin interrupción, cumplirá con cualquier estándar de rendimiento o fiabilidad o estará libre de errores o que cualquier error o defecto puede o será corregido. <br /> <br />
                    Sin perjuicio de lo anterior, ni Swapper ni ningún proveedor de Swapper ofrece ninguna representación o garantía de ningún tipo, expresa o implícita (i) en cuanto al funcionamiento o la disponibilidad del sitio web/aplicación, o la información, el contenido y los materiales o productos incluidos en él; (ii) que el sitio web/aplicación no sufra interrupciones o esté libre de errores; (iii) en cuanto a la exactitud, fiabilidad o actualidad de cualquier información o contenido proporcionado a través del sitio web/aplicación; o (iv) que el sitio web/aplicación, sus servidores, el contenido o los correos electrónicos enviados desde Swapper o en su nombre estén libres de virus, scripts, troyanos, gusanos, malware, bombas de tiempo u otros componentes dañinos. <br /> <br />
                    Algunas jurisdicciones no permiten la exclusión o las limitaciones de las garantías implícitas o las limitaciones de los derechos legales aplicables de un consumidor, por lo que algunas o todas las exclusiones y limitaciones anteriores pueden no aplicarse en su caso.
                    </p>  
                    </div>
                </div>
                <br />
                
                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-10'>

                    <h3 className='terms__h3'>Limitación de la responsabilidad</h3>
                    <p className='terms__p'>
                    Sin perjuicio de los daños en los que pueda incurrir, toda la responsabilidad de Swapper y de cualquiera de sus proveedores en virtud de cualquier disposición de este Acuerdo y su recurso exclusivo para todo lo anterior se limitará a la cantidad efectivamente pagada por usted por el sitio web/la aplicación. <br /> <br />
                    En la medida máxima permitida por la ley aplicable, en ningún caso Swapper o sus proveedores serán responsables de ningún daño especial, incidental, indirecto o consecuente (incluyendo, pero sin limitarse a, daños por pérdida de beneficios, por pérdida de datos u otra información, por interrupción del negocio, por daños personales, por pérdida de privacidad que surjan o estén relacionados de algún modo con el uso o la imposibilidad de usar el sitio web/aplicación, el software de terceros y/o el hardware de terceros utilizado con el sitio web/aplicación, o de otro modo en relación con cualquier disposición de este Acuerdo), incluso si Swapper o cualquier proveedor ha sido advertido de la posibilidad de tales daños e incluso si el remedio no cumple con su propósito esencial. <br /> <br />
                    Algunos estados/jurisdicciones no permiten la exclusión o limitación de los daños incidentales o consecuentes, por lo que la limitación o exclusión anterior puede no aplicarse en su caso.
                    </p>  
                    </div>
                </div>
                <br />
                
                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-10'>

                    <h3 className='terms__h3'>Divisibilidad</h3>
                    <p className='terms__p'>
                    Si alguna disposición de este Contrato se considera inaplicable o inválida, dicha disposición se modificará e interpretará para cumplir los objetivos de dicha disposición en la mayor medida posible según la ley aplicable y las disposiciones restantes continuarán en plena vigencia. <br /> <br />
                    Este Contrato, junto con la Política de Privacidad y cualquier otro aviso legal publicado por Swapper en los Servicios, constituirá el acuerdo completo entre usted y Swapper en relación con los Servicios. Si alguna de las disposiciones de este Contrato es considerada inválida por un tribunal de jurisdicción competente, la invalidez de dicha disposición no afectará a la validez de las restantes disposiciones de este Contrato, que seguirán siendo plenamente vigentes. Ninguna renuncia a cualquier término de este Contrato se considerará una renuncia posterior o continua a dicho término o a cualquier otro término, y el hecho de que Swapper no haga valer cualquier derecho o disposición en virtud de este Contrato no constituirá una renuncia a dicho derecho o disposición. USTED Y Swapper ACUERDAN QUE CUALQUIER CAUSA DE ACCIÓN QUE SURJA DE LOS SERVICIOS O QUE ESTÉ RELACIONADA CON LOS MISMOS DEBE COMENZAR DENTRO DE UN (1) AÑO DESDE
                    QUE SE PRODUZCA LA CAUSA DE ACCIÓN. DE LO CONTRARIO, DICHA CAUSA DE ACCIÓN PRESCRIBE PERMANENTEMENTE
                    </p>  
                    </div>
                </div>
                <br />
                
                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-10'>

                    <h3 className='terms__h3'>Renuncia</h3>
                    <p className='terms__p'>
                    Salvo lo dispuesto en el presente documento, el hecho de no ejercer un derecho o de no exigir el cumplimiento de una obligación en virtud del presente Acuerdo no afectará a la capacidad de una parte para ejercer dicho derecho o exigir dicho cumplimiento en cualquier momento posterior, ni la renuncia a un incumplimiento constituirá una renuncia a cualquier incumplimiento posterior. <br /> <br />
                    La falta de ejercicio, y el retraso en el ejercicio, por parte de cualquiera de las partes, de cualquier derecho o poder en virtud del presente Acuerdo no supondrá una renuncia a dicho derecho o poder. El ejercicio único o parcial de cualquier derecho o facultad en virtud del presente Acuerdo tampoco impedirá el ejercicio posterior de ese o cualquier otro derecho concedido en el mismo. En caso de conflicto entre el presente Acuerdo y cualquier otra condición de compra o de otro tipo, prevalecerán los términos del presente Acuerdo.
                    </p>  
                    </div>
                </div>
                <br />

                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-10'>

                    <h3 className='terms__h3'>Modificaciones del presente Contrato</h3>
                    <p className='terms__p'>
                    Swapper se reserva el derecho, a su sola discreción, de modificar o sustituir este Acuerdo en cualquier momento. En caso de que una revisión sea material, le avisaremos con al menos 30 días de antelación antes de que los nuevos términos entren en vigor. Lo que constituye un cambio material se determinará a nuestra entera discreción. <br /> <br />
                    Si continúa accediendo o utilizando nuestro sitio web o aplicación después de que las revisiones entren en vigor, usted acepta quedar obligado por los términos revisados. Si no está de acuerdo con los nuevos términos, ya no está autorizado a utilizar Swapper.
                    </p>  
                    </div>
                </div>
                <br />
                
                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-10'>

                    <h3 className='terms__h3'>Acuerdo completo</h3>
                    <p className='terms__p'>
                    El Acuerdo constituye el acuerdo completo entre usted y Swapper con respecto a su uso del sitio web/aplicación y sustituye todos los acuerdos anteriores y contemporáneos, escritos u orales, entre usted y Swapper. <br /> <br />
                    Usted puede estar sujeto a términos y condiciones adicionales que se aplican cuando utiliza o compra otros servicios de Swapper, que Swapper le proporcionará en el momento de dicho uso o compra.
                    </p>  
                    </div>
                </div>
                <br />
                
                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-10'>

                    <h3 className='terms__h3'>Actualizaciones de nuestras condiciones</h3>
                    <p className='terms__p'>
                        Podemos cambiar nuestro Servicio y nuestras políticas, y es posible que tengamos que hacer cambios en estas Condiciones para que reflejen con exactitud nuestro Servicio y nuestras políticas. A menos que la ley exija lo contrario, le notificaremos (por ejemplo, a través de nuestro Servicio) antes de realizar cambios en estas Condiciones y le daremos la oportunidad de revisarlos antes de que entren en vigor. Entonces, si sigue utilizando el Servicio, quedará obligado por las Condiciones actualizadas. Si no desea aceptar estas Condiciones o las actualizadas, puede eliminar su cuenta.
                    </p>  
                    </div>
                </div>
                <br />
              
                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-10'>

                    <h3 className='terms__h3'>Propiedad intelectual</h3>
                    <p className='terms__p'>
                       El sitio web/la aplicación y todo su contenido, características y funcionalidad (incluyendo, pero sin limitarse a toda la información, el software, el texto, las pantallas, las imágenes, el vídeo y el audio, así como el diseño, la selección y la disposición de los mismos), son propiedad de Swapper, sus licenciatarios u otros proveedores de dicho material y están protegidos por las leyes venezolanas e internacionales de derechos de autor, marcas registradas, patentes, secretos comerciales y otros derechos de propiedad intelectual o de propiedad. El material no puede ser copiado, modificado, reproducido, descargado o distribuido de ninguna manera, en su totalidad o en parte, sin el permiso expreso y previo por escrito de Swapper, a menos que, y salvo que se disponga expresamente en estos Términos y Condiciones. Queda prohibido cualquier uso no autorizado del material.                    
                    </p>  
                    </div>
                </div>
                <br />
                
                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-10'>

                    <h3 className='terms__h3'>Acuerdo de arbitraje</h3>
                    <p className='terms__p'>
                    Esta sección se aplica a cualquier disputa, EXCEPTO QUE NO INCLUYA UNA DISPUTA RELATIVA A RECLAMACIONES POR JUSTICIA O EQUIDAD EN RELACIÓN CON EL CUMPLIMIENTO O LA VALIDEZ DE SUS DERECHOS DE PROPIEDAD INTELECTUAL O LOS DE Swapper. El término "disputa" significa cualquier disputa, acción u otra controversia entre usted y Swapper en relación con los Servicios o este acuerdo, ya sea por contrato, garantía, agravio, estatuto, reglamento, ordenanza o cualquier otra base legal o equitativa. A "Disputa" se le dará el significado más amplio posible que permita la ley.
                    </p>  
                    </div>
                </div>
                <br />
                
                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-10'>

                    <h3 className='terms__h3'>Aviso de disputa</h3>
                    <p className='terms__p'>
                        En el caso de una disputa, usted o Swapper deben entregar a la otra parte una Notificación de Disputa, que es una declaración escrita que establece el nombre, la dirección y la información de contacto de la parte que la entrega, los hechos que dan lugar a la disputa y la reparación solicitada. Debe enviar cualquier notificación de disputa por correo electrónico a: info@swapper.media. Swapper le enviará cualquier Notificación de Disputa por correo a su dirección si la tenemos, o de lo contrario a su dirección de correo electrónico. Usted y Swapper intentarán resolver cualquier disputa a través de una negociación informal en un plazo de sesenta (60) días a partir de la fecha de envío de la Notificación de Disputa. Transcurridos los sesenta (60) días, usted o Swapper podrán iniciar un arbitraje.                    
                    </p>  
                    </div>
                </div>
                <br />
             
                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-10'>

                    <h3 className='terms__h3'>Arbitraje vinculante</h3>
                    <p className='terms__p'>
                    Si usted y Swapper no resuelven cualquier disputa por medio de una negociación informal, cualquier otro esfuerzo para resolver la disputa se llevará a cabo exclusivamente por medio de un arbitraje vinculante como se describe en esta sección. Usted renuncia al derecho de litigar (o participar como parte o miembro de una clase) todas las disputas en los tribunales ante un juez o un jurado. El litigio se resolverá mediante arbitraje vinculante de acuerdo con las normas de arbitraje comercial de la Asociación Americana de Arbitraje. Cualquiera de las partes podrá solicitar cualquier medida cautelar o preliminar a cualquier tribunal de jurisdicción competente, según sea necesario para proteger los derechos o la propiedad de la parte en espera de la finalización del arbitraje. Todos y cada uno de los costes, honorarios y gastos legales, contables y de otro tipo en los que incurra la parte vencedora correrán a cargo de la parte no vencedora.                    
                    </p>
                    </div>
                </div>
                <br />
                
                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-10'>

                    <h3 className='terms__h3'>Envíos y privacidad</h3>
                    <p className='terms__p'>
                    En el caso de que usted envíe o publique cualquier idea, sugerencia creativa, diseño, fotografía, información, publicidad, datos o propuestas, incluyendo ideas para productos, servicios, características, tecnologías o promociones nuevas o mejoradas, usted acepta expresamente que tales envíos serán automáticamente tratados como no confidenciales y no propietarios y pasarán a ser propiedad exclusiva de Swapper sin ninguna compensación o crédito para usted. Swapper y sus afiliados no tendrán ninguna obligación con respecto a dichos envíos o publicaciones y podrán utilizar las ideas contenidas en dichos envíos o publicaciones para cualquier propósito en cualquier medio a perpetuidad, incluyendo, pero no limitándose a, el desarrollo, fabricación y comercialización de productos y servicios utilizando dichas ideas.                    
                    </p>
                    </div>
                </div>
                <br />
                
                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-10'>

                    <h3 className='terms__h3'>Promociones</h3>
                    <p className='terms__p'>
                    Swapper puede, de vez en cuando, incluir concursos, promociones, sorteos u otras actividades ("Promociones") que requieren que usted envíe material o información relativa a su persona. Tenga en cuenta que todas las Promociones pueden estar regidas por reglas separadas que pueden contener ciertos requisitos de elegibilidad, tales como restricciones en cuanto a la edad y la ubicación geográfica. Usted es responsable de leer todas las normas de las Promociones para determinar si puede participar o no. Si participa en una promoción, acepta respetar y cumplir todas las normas de la promoción. <br /> <br />
                    Pueden aplicarse términos y condiciones adicionales a las compras de bienes o servicios en o a través de los Servicios, cuyos términos y condiciones forman parte de este Acuerdo por esta referencia.
                    </p>
                    </div>
                </div>
                <br />
                
                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-10'>

                    <h3 className='terms__h3'>Errores tipográficos</h3>
                    <p className='terms__p'>
                    En el caso de que un producto y/o servicio figure con un precio incorrecto o con información incorrecta debido a un error tipográfico, tendremos derecho a rechazar o cancelar cualquier pedido realizado del producto y/o servicio que figure con el precio incorrecto. Tendremos derecho a rechazar o cancelar cualquier pedido de este tipo, independientemente de que se haya confirmado el pedido y se haya cargado en su tarjeta de crédito. Si ya se ha cargado la compra en su tarjeta de crédito y se cancela su pedido, emitiremos inmediatamente un crédito en la cuenta de su tarjeta de crédito u otra cuenta de pago por el importe del cargo.
                    </p>
                    </div>
                </div>
                <br />
                
                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-10'>

                    <h3 className='terms__h3'>Varios</h3>
                    <p className='terms__p'>
                        Si, por cualquier motivo, un tribunal de la jurisdicción competente considera que alguna disposición o parte de estos Términos y Condiciones es inaplicable, el resto de estos Términos y Condiciones continuará en pleno vigor y efecto. Cualquier renuncia a cualquier disposición de estos Términos y Condiciones sólo será efectiva si se hace por escrito y está firmada por un representante autorizado de Swapper. Swapper tendrá derecho a una medida cautelar u otro tipo de compensación equitativa (sin la obligación de depositar ninguna fianza o garantía) en el caso de que se produzca un incumplimiento o un incumplimiento anticipado por su parte. El Servicio no está destinado a ser distribuido o utilizado por ninguna persona o entidad en ninguna jurisdicción o país donde dicha distribución o uso sea contrario a la ley o a la normativa. En consecuencia, las personas que decidan acceder al Servicio de Swapper desde otros lugares lo hacen por su propia iniciativa y son los únicos responsables del cumplimiento de las leyes locales, si y en la medida en que las leyes locales sean aplicables. Estos Términos y Condiciones (que incluyen e incorporan la Política de Privacidad de Swapper) contienen todo el acuerdo, y sustituyen todos los acuerdos anteriores, entre usted y Swapper en relación con su objeto, y no pueden ser cambiados o modificados por usted. Los títulos de las secciones utilizadas en este Acuerdo son sólo por conveniencia y no se les dará ninguna importancia legal.                    
                    </p>
                    </div>
                </div>
                <br />
              
                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-10'>

                    <h3 className='terms__h3'>Descargo de responsabilidad</h3>
                    <p className='terms__p'>
                    Swapper no es responsable de ningún contenido, código o cualquier otra imprecisión. Swapper no ofrece garantías ni avales. <br /> <br />

                    En ningún caso Swapper será responsable de ningún daño especial, directo, indirecto, consecuente o incidental, ni de ningún daño, ya sea en una acción de contrato, negligencia u otro agravio, que surja de o en relación con el uso del Servicio o los contenidos del Servicio. La Compañía se reserva el derecho de hacer adiciones, supresiones o modificaciones a los contenidos del Servicio en cualquier momento sin previo aviso. <br /> <br />
                    El Servicio Swapper y sus contenidos se proporcionan "tal cual" y "como están disponibles" sin ninguna garantía o representación de ningún tipo, ya sea expresa o implícita. Swapper es un distribuidor y no un editor del contenido suministrado por terceros; como tal, Swapper no ejerce ningún control editorial sobre dicho contenido y no ofrece ninguna garantía o representación en cuanto a la exactitud, fiabilidad o actualidad de cualquier información, contenido, servicio o mercancía proporcionada a través de o accesible a través del Servicio Swapper. Sin limitar lo anterior, Swapper renuncia específicamente a todas las garantías y representaciones en cualquier contenido transmitido en o en conexión con el Servicio Swapper o en los sitios que puedan aparecer como enlaces en el Servicio Swapper, o en los productos proporcionados como parte de, o de otra manera en conexión con, el Servicio Swapper, incluyendo sin limitación cualquier garantía de comerciabilidad, idoneidad para un propósito particular o no violación de los derechos de terceros. Ningún consejo oral o información escrita dada por Swapper o cualquiera de sus afiliados, empleados, funcionarios, directores, agentes o similares creará una garantía. La información sobre precios y disponibilidad está sujeta a cambios sin previo aviso. Sin perjuicio de lo anterior, Swapper no garantiza que el Servicio Swapper sea ininterrumpido, sin interrupciones, oportuno o libre de errores.
                    </p>
                    </div>
                </div>
                <br />
                
                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-10'>

                    <h3 className='terms__h3'>Contacto con nosotros</h3>
                    <p className='terms__p'>
                    No dude en ponerse en contacto con nosotros si tiene alguna pregunta. <br /> <br />
                    Por correo electrónico: info@swapper.media <br />
                    A través de este enlace: www.swapper.media
                    </p>
                    </div>
                </div>
                <br />

            </div>
            <Footer />
    </div>
  )
}
