import React from 'react';

import './WatermarkList.css';

class WatermarkListItem extends React.Component{


    handleStatiscsClick(){
        this.setState({statistics: true, statisticsWatermark: this.props.watermark.props.id})
    }
    render(){
        var icon = '';
        var file = '';
        const inProcess = this.props.watermark.props.children.status !== 1;
        // const inProcess = this.props.watermark.props.children.status == 1;
        // var description = '';
        for (var meta in this.props.watermark.props.children.watermarks_meta){
            if (this.props.watermark.props.children.watermarks_meta[meta].name === 'file'){
                file = this.props.watermark.props.children.watermarks_meta[meta].value;
            }
            if (this.props.watermark.props.children.watermarks_meta[meta].name === 'icon'){
                icon = this.props.watermark.props.children.watermarks_meta[meta].value;
            }else{
                icon = './img/swapper/icono-1.svg';
            }
        }
        // this.props.handleStatisticsClick();
        return(
            <div className="watermark">
                <div className="row pt-4 align-items-center justify-content-center">
                    <div className="col-4">
                        <div className="row align-items-center justify-content-center">
                            <img alt="icon watermark" className="icon-image" src={icon} />
                        </div>
                    </div>
                    <div className="col-6 pb-1 px-2 text-break">
                        <p className="font-weight-bold color-black m-0 watermark-font-size">{this.props.watermark.props.children.name}</p>
                    </div>
                </div>
                <div className="row align-items-center justify-content-center">
                    {inProcess ? (
                        <div className="mt-1 small-font-size">
                            <p>En proceso</p>
                        </div>
                    ) : (
                        <div className="row justify-content-end pr-5 pb-2 pb-md-0">
                            <a className="col-2 px-md-1 px-2 pb-2" href={file} download target="blank">
                                <img alt="Botón descargar swappy" className="size-2-rem" src="./img/swapper/descargar.svg" />
                            </a>
                            <img alt="Botón ir a estadística de swappy" className="col-2 px-md-2 px-3 pb-2 statistic-hover size-11-rem" onClick={() => this.props.handleStatisticsClick(this.props.watermark.props.children.id, this.props.watermark.props.children.name)} src="./img/swapper/estadistica.svg" />
                        </div>
                    )}
                </div>
            </div>
        )
    }
}


class WatermarksList extends React.Component{

    render(){
        return(
            <div className="row py-2 align-items-start justify-content-center">
                <div className="col-lg-12 col-md-12 col-xl-12 large-height">
                    <div className="row">
                        {this.props.watermarks.map(watermark =>{
                            return(
                                <div className="col-lg-3 col-md-4 mt-3 "  key={watermark.id}>
                                    <WatermarkListItem handleStatisticsClick={this.props.handleStatisticsClick} watermark={watermark} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        )
    }
}


export default WatermarksList;
