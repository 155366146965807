import React, { Component } from "react";
import { Link } from "react-router-dom";

class Landing extends Component {
    render() {
        return (
            <div>
                <div className="site-mobile-menu site-navbar-target">
                    <div className="site-mobile-menu-header">
                        <div className="site-mobile-menu-close mt-3">
                            <span className="icofont-close js-menu-toggle"></span>
                        </div>
                    </div>
                    <div className="site-mobile-menu-body"></div>
                </div>
                <header className="site-navbar js-sticky-header site-navbar-target" role="banner">

                    <div className="container">
                        <div className="row align-items-center">

                            <div className="col-6 col-lg-2">
                                <h1 className="mb-0 site-logo"><a href="/" className="mb-0"><img alt="Swapper Logo" src="img/swapper/swapper-logo-1.svg"/></a></h1>
                            </div>

                            <div className="col-12 col-md-10 d-none d-lg-block">
                                <nav className="site-navigation position-relative text-right" role="navigation">

                                    <ul className="site-menu main-menu js-clone-nav mr-auto d-none d-lg-block">
                                        <li><a href="#contacto" className="nav-link">Contacto</a></li>
                                        <li><Link className="nav-link" to={"/privacy"}>Políticas de privacidad</Link></li>
                                        <li><Link className="nav-link" to={"/terms"}>Términos y condiciones</Link></li>
                                        <li><Link className="nav-link" to={"/login"}>Iniciar sesión</Link></li>
                                    </ul>
                                </nav>
                            </div>

                            <div className="col-6 d-inline-block d-lg-none ml-md-0 py-3">

                                <a href="#" className="burger site-menu-toggle js-menu-toggle" data-toggle="collapse" data-target="#main-navbar">
                                    <span></span>
                                </a>
                            </div>

                        </div>
                    </div>

                </header>
            </div>

        );
    }
}

export default Landing;