import React, { Component } from "react";
import axios from "axios";

class Landing extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            input: {},
            errors: {},
            countries: "",
            messageResponse: ""
        }
        this.loadCountries();

    }

    handleSubmit(event) {
        event.preventDefault();
        if(this.validate()){
      
    
            const SERVER_URL = `https://swapper.media/public/app`;

            axios
            .post(SERVER_URL + "/api/contacto", {
                name : event.target[0].value,
                phone :event.target[1].value,
                email1 : event.target[2].value,
                cargo : event.target[3].value,
                empresa : event.target[4].value,
                pais : event.target[5].value
            })
            .then(response => {
                if(response.data.code === 200){
                    this.setState({ messageResponse: "Mensaje enviado con éxito"});
                    let input = {};
                    input["name"] = "";
                    input["email"] = "";
                    input["phone"] = "";
                    input["cargo"] = "";
                    input["empresa"] = "";
                    input["pais"] = "";
                    this.setState({input:input});
                    window.location = '/thanks';
                }
            })
            .catch(error => {
            });
            
            
        }
    }

    async loadCountries() {

        const SERVER_URL = `https://swapper.media/public/app`;

        const res = await axios.get(SERVER_URL + "/api/countries")

        const data = res.data.data;
        const options = data.map(d => ({
            "value": d.name,
            "label": d.name
        }));
        this.setState({ countries: options });
    }

    componentDidMount() {
        this.loadCountries();

    }

    handleChange(event) {
        let input = this.state.input;
        input[event.target.name] = event.target.value;
      
        this.setState({
          input
        });

        this.validate();
      }

    validate(){
        let input = this.state.input;
        let errors = {};
        let isValid = true;
    
        if (!input["name"]) {
          isValid = false;
          errors["name"] = "Campo requerido.";
        }else{
           errors["name"] = ""; 
        }
    
        if (!input["email"]) {
          isValid = false;
          errors["email"] = "Campo requerido.";
        }else{
            errors["email"] = ""; 
        }

        if (!input["phone"]) {
            isValid = false;
            errors["phone"] = "Campo requerido.";
        }else if (input["phone"].length < 9) {
            isValid = false;
            errors["phone"] = "Minimo requerido 10";
        }else{
            errors["phone"] = ""; 
        }

        if (!input["cargo"]) {
            isValid = false;
            errors["cargo"] = "Campo requerido.";
        }else{
            errors["cargo"] = ""; 
        }

        if (!input["empresa"]) {
            isValid = false;
            errors["empresa"] = "Campo requerido.";
        }else{
            errors["empresa"] = ""; 
        }

        if (!input["pais"]) {
            isValid = false;
            errors["pais"] = "Campo requerido.";
        }else{
            errors["pais"] = ""; 
        }
    
        if (typeof input["email"] !== "undefined") {
            
          var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          if (!pattern.test(input["email"])) {
            isValid = false;
            errors["email"] = "Ingreso un email valido.";
          }
        }
    
        this.setState({
          errors: errors
        });
    
        return isValid;
    }

    render() {

        return (
            <div>
                <main id="main">
                    <section id="secondSection" className="section pt-auto pt-xl-0">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4" data-aos="fade-up" data-aos-delay="">
                                    <div className="feature-1 text-center">
                                        <div className="wrap-icon icon-1">
                                            <div className="w-100"><img alt="Usuarios de la app" src="img/swapper/icono-1.svg" /></div>
                                        </div>
                                        <h3 className="mb-3 nunito">Usuarios de la app</h3>
                                        <p>Luego de registrarte y permitir el funcionamiento de la app en segundo plano en el dispositivo, la aplicación detectará todos los archivos multimedia con el sello de Swapper. Los usuarios que acumulen estas escuchas participarán por premios y recompensas.</p>
                                    </div>
                                </div>
                                <div className="col-md-4" data-aos="fade-up" data-aos-delay="200">
                                    <div className="feature-1 text-center">
                                        <div className="wrap-icon icon-1">
                                            <div className="w-100"><img alt="Estadísticas" src="img/swapper/icono-3.svg" /></div>
                                        </div>
                                        <h3 className="mb-3 nunito">Estadísticas</h3>
                                        <p>Las radios, televisoras, marcas o artistas registradas en Swapper, contarán con un tablero de estadísticas en el cual tendrán acceso a toda la información recopilada de las audiencias y los datos demográficos, siempre protegiendo y manteniendo de forma segura los datos de nuestros usuarios.</p>
                                    </div>
                                </div>
                                <div className="col-md-4" data-aos="fade-up" data-aos-delay="100">
                                    <div className="feature-1 text-center">
                                        <div className="wrap-icon icon-1">
                                            <div className="w-100"><img alt="Ganar Ganar" src="img/swapper/icono-2.svg" /></div>
                                        </div>
                                        <h3 className="mb-3 nunito">Ganar Ganar</h3>
                                        <p>Los usuarios mientras más tiempo mantengan la aplicación activa más oportunidades tendrán de ganar premios, los cuales podrán ser canjeados a través de la app. Las radios, televisoras, marcas y artistas obtendrán ratings, certificados de transmisión y engagement de su programación, mensajes y canciones.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>

                    <section id="messageGirl" className="section pb-0">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xs-12 order-12 mt-4 mt-md-0 order-md-6 col-md-6 ml-auto">
                                    <h2 className="mb-4 title-Form nunito">Fácil y sencillo</h2>
                                    <p className="mb-4">Una vez descargada la APP y habiéndose registrado, el usuario no debe hacer ninguna acción adicional para ganar.</p>
                                    <p className="mb-4">Nuestra tecnología reconoce marcas que insertamos en los audios, la cuales no son percibibles para el oído humano, de manera que tienen la seguridad y tranquilidad que a través de la app y nuestra plataforma no se puede escuchar ni grabar conversaciones o cualquier otro audio que si pueda percibir el oído humano.</p>
                                    <p className="mb-4">La APP tiene bajo consumo de datos y de batería.</p>
                                </div>
                                <div className="col-xs-12 order-1 col-md-6 order-md-6" data-aos="fade-left">
                                    <img src="img/swapper/mujer-sinfondo.png" alt="mujer" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="contacto" className="section pb-0">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-4 mr-auto order-2 pb-2">
                                    <h1 className='title-Form py-3 m-0 text-center text-lg-left nunito'>Hablemos</h1>
                                    <div id="formularioContacto" className='p-3'>
                                        <form name='contactanos' id='contactanos' method='POST' onSubmit={this.handleSubmit}>
                                            <div className='col-12 mb-2'>
                                                <input placeholder='Nombre y apellido' name='name' onBlur={this.handleChange} onChange={this.handleChange} id='name' className='form-control' value={this.state.input.name}/>
                                                <div className="text-danger">{this.state.errors.name}</div>
                                            </div>
                                            <div className='col-12 mb-2'>
                                                <input type="number" placeholder='Número de teléfono' name='phone' onBlur={this.handleChange} onChange={this.handleChange} id='phone' className='form-control' value={this.state.input.phone} minLength='12'/>
                                                <div className="text-danger">{this.state.errors.phone}</div>
                                            </div>
                                            <div className='col-12 mb-2'>
                                                <input type="email" placeholder='Correo electrónico' name='email' onBlur={this.handleChange} onChange={this.handleChange} id='email' className='form-control' value={this.state.input.email}/>
                                                <div className="text-danger">{this.state.errors.email}</div>
                                            </div>
                                            <div className='col-12 mb-2'>
                                                <input placeholder='Empresa' name='empresa' onBlur={this.handleChange} onChange={this.handleChange} id='empresa' className='form-control' value={this.state.input.empresa}/>
                                                <div className="text-danger">{this.state.errors.empresa}</div>
                                            </div>
                                            <div className='col-12 mb-2'>
                                                <input placeholder='Cargo de Empresa' name='cargo' onBlur={this.handleChange} onChange={this.handleChange} id='cargo' className='form-control' value={this.state.input.cargo}/>
                                                <div className="text-danger">{this.state.errors.cargo}</div>
                                            </div>
                                            <div className='col-12 mb-2'>
                                                <select id='pais' name='pais' onBlur={this.handleChange} onChange={this.handleChange} className='form-control' value={this.state.input.pais}>
                                                    <option value="" hidden>Seleccione</option>
                                                    {this.state.countries.length > 0 &&
                                                        this.state.countries.map((item, key) => (
                                                            <option key={key} value={item.label}>{item.label}</option>
                                                        ))}
                                                </select>
                                                <div className="text-danger">{this.state.errors.pais}</div>
                                            </div>
                                            <div className='messageContactanos'>{this.state.messageResponse}</div>
                                            <div className='col-12 text-right'>
                                                <button type='Submit' className='btn btn-success'>Enviar</button>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                                <div className="col-md-6" data-aos="fade-right">
                                    <img src="img/swapper/hombre-sinfondo.png" alt="Hombre" className="imagen-hombre-sinfondo img-fluid" />
                                </div>
                            </div>
                        </div>
                    </section>

                </main>
            </div>

        );
    }
}

export default Landing;