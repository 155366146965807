import React, { Component } from "react";
import axios from "axios";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
        messageResponse: ""
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    const SERVER_URL = `https://swapper.media/public/app`;

    axios
      .post(SERVER_URL + "/api/partner/login", {
        email: event.target[0].value,
        password: event.target[1].value,
      })
      .then(response => {
        console.log(response);
        if(response.data.code === 200){
            this.setState({ messageResponse: "Login con exito"});
            localStorage.setItem('accessToken', response.data.access_token);
            localStorage.setItem('emailPartner', event.target[0].value);
            window.location = '/profile';
        }
      })
      .catch(error => {
        console.log(error.response);
        if(error.response.data.code === 401){
            this.setState({ messageResponse: error.response.data.message});
        }
      });
  }

  render() {
    return (
      <form id="login" onSubmit={this.handleSubmit}>
        <h3 className="text-white font-weight-light my-4 nunito">
          ¡Bienvenidos de vuelta!
        </h3>

        <div className="form-group border-bottom-login">
          <input
            type="email"
            className="form-control"
            placeholder="usuario / correo electrónico"
            required="required"
          />
        </div>

        <div className="form-group border-bottom-login">
          <input
            type="password"
            className="form-control"
            placeholder="contraseña"
            required="required"
          />
        </div>

        <button type="submit" className="btn btn-login btn-block">          
          Entra
        </button>

        <div className="mt-5 text-white text-center">
          {this.state.messageResponse}
        </div>

        <div className="mt-5 text-white text-center">
          <a  className="nav-link text-white p-0 px-2 text-center" href="/">¿No tienes cuenta ? Contacto</a>
        </div>
      </form>
    );
  }
}
