import React from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import axios from "axios";
import moment from 'moment';

// import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './HeaderStatistics.css'
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';

class HeaderStatistics extends React.Component{
    
    constructor(props) {
        super(props);
        this.state = {
            countries: '',
            cities: '',
            genders: '',
            percentages: '',
            warningByTime: false,
            startDate: '',
            finishDate: '',
            countrySelected:'',
            genderSelected: '',
            percentageSelected: '',
            citySelected: 'none',
            dataLocation: '',
            handleCityChange: '',
        }
        this.handleApplyChange = this.handleApplyChange.bind(this);
        this.loadCountriesByDate = this.loadCountriesByDate.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.loadStatisticsByHour = this.loadStatisticsByHour.bind(this);
        this.reloadStatistics = this.reloadStatistics.bind(this);
        this.handleSearchByLocation =this.handleSearchByLocation.bind(this);
        this.handleApplyDateRange = this.handleApplyDateRange.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);
        this.handlePercentageChange = this.handlePercentageChange.bind(this);
        this.alertDateRangeExceed = this.alertDateRangeExceed.bind(this);
        this.handleGenderChange = this.handleGenderChange.bind(this);
        this.loadGenders = this.loadGenders.bind(this);
        this.loadPercentages = this.loadPercentages.bind(this);
        this.loadStatisticsByHour();
        if(this.props.header === 'ByLocation'){
            this.loadCountriesByHour();
        }
    }
    alertDateRangeExceed(){
        alert('Seleccionó un rango de fechas mayor al permitido. \n Por favor, ingrese un rango de fecha más corto.');
        this.setState({warningByTime: false});

    }
    componentDidMount(){
        window.scrollTo(0, 0)          
        if(this.props.header === 'ByLocation'){
            this.loadCountriesByHour();

        }
    }


    handleApplyChange(event, dates){
        if(dates.endDate.diff(dates.startDate, 'days')<30){
            if(dates.startDate.diff(dates.endDate, 'days')===0){
                this.props.changeDateRangeType('byHour')
                this.loadStatisticsByHour(dates.startDate, dates.endDate);
            }else{
                this.props.changeDateRangeType('byDate');
                this.loadStatisticsByTime(dates.startDate, dates.endDate);
            }
            this.setState({warningByTime: false});
        }else{
            this.setState({warningByTime: true});
        }
    }

    handleSearchByLocation(){
        if(this.state.startDate === '' || this.state.endDate === ''){
            alert('Debes seleccionar una fecha o un rango de fechas para realizar la búsqueda');
        }else{

            this.props.changeCountry(this.state.countrySelected);
            if(this.state.endDate.diff(this.state.startDate, 'days') <30){
                if(this.state.startDate.diff(this.state.endDate, 'days') === 0){
                    this.props.changeDateRangeType('byHour')
                    this.loadStatisticsByHour(this.state.startDate, this.state.endDate);
                    this.loadCountriesByHour(this.state.startDate, this.state.endDate);
                }else{
                    this.props.changeDateRangeType('byDate');
                    this.loadStatisticsByTime(this.state.startDate, this.state.endDate);
                    this.loadCountriesByDate(this.state.startDate, this.state.endDate);
                }
                this.setState({warningByTime: false});
            }else{
                this.setState({warningByTime: true});
            }

        }
    }
    handleSearchByGender(){

        if(this.state.startDate === '' || this.state.endDate === ''){
            alert('Debes seleccionar una fecha o un rango de fechas para realizar la búsqueda');
        }else{

            if(this.state.endDate.diff(this.state.startDate, 'days') <30){
                if(this.state.startDate.diff(this.state.endDate, 'days') === 0){
                    this.props.changeDateRangeType('byHour')
                    this.loadStatisticsByHour(this.state.startDate, this.state.endDate);
                }else{
                    this.props.changeDateRangeType('byDate');
                    this.loadStatisticsByTime(this.state.startDate, this.state.endDate);
                }
                this.setState({warningByTime: false});
            }else{
                this.setState({warningByTime: true});
            }

        }
    }
    
    handleSearchByPercentage(){
        if(this.state.startDate === '' || this.state.endDate === ''){
            alert('Debes seleccionar una fecha o un rango de fechas para realizar la búsqueda');
        }else{

            if(this.state.endDate.diff(this.state.startDate, 'days') <30){
                if(this.state.startDate.diff(this.state.endDate, 'days') === 0){
                    this.props.changeDateRangeType('byHour')
                    this.loadStatisticsByHour(this.state.startDate, this.state.endDate);
                }else{
                    this.props.changeDateRangeType('byDate');
                    this.loadStatisticsByTime(this.state.startDate, this.state.endDate);
                }
                this.setState({warningByTime: false});
            }else{
                this.setState({warningByTime: true});
            }
        }
    }
    
    handleApplyDateRange(event, dates){
        this.setState({startDate: dates.startDate, endDate: dates.endDate});
    }

    reloadStatistics(){
        this.props.changeLoadingState(true);
        this.loadStatisticsByHour();
        if(this.props.header === 'ByLocation'){
            this.loadCountriesByHour();
        }
        this.setState({endDate: '',startDate: '', cities: '', countries: '', countrySelected: '', citySelected: 'none', genderSelected: '', genders: ''});
    }

    async loadStatisticsByTime(from=moment.now(), to=moment.now()){
        this.props.changeLoadingState(true);
        from = (moment(from).format('YYYY-MM-DD') + ' 00:00:00')
        to = (moment(to).format('YYYY-MM-DD') + ' 23:59:59')
        const SERVER_URL = `https://swapper.media/public/app`;

        
        const config = {
            headers: { Authorization: `Bearer `+localStorage.getItem('accessToken'), 'Content-Type': 'multipart/form-data', "type": "formData"}
        };

        var sendData = new FormData();
        sendData.append("email", localStorage.getItem('emailPartner'));
        sendData.append("watermark_id", this.props.statisticsWatermark);
        sendData.append("from", from.toString());
        sendData.append("to", to.toString());

        if(this.state.countrySelected !== ''){
            sendData.append("country", this.state.countrySelected);
        }
        if(this.state.citySelected !== 'none'){
            sendData.append("city", this.state.citySelected);
            this.props.changeIsSelectedCity(true);
        }else{
            this.props.changeIsSelectedCity(false);

        }

        
        if(this.state.percentageSelected !== ''){
            sendData.append("percentage", this.state.percentageSelected);   
            this.props.changeIsSelectedPercentage(true); 
        }else{
            this.props.changeIsSelectedPercentage(false);
        }
    

        if(this.state.genderSelected !== ''){
            sendData.append("gender", this.state.genderSelected);   

            this.props.changeIsSelectedGender(true); 
        }else{
            this.props.changeIsSelectedGender(false);
        }

        var res;
        if(this.props.header === 'ByTime'){
            res = await axios.post(SERVER_URL + "/api/statistics/byday", sendData, config);
        }else if(this.props.header === 'ByLocation'){
            res = await axios.post(SERVER_URL + "/api/statistics/bydayubication", sendData, config);
        }else if(this.props.header === 'ByGender'){
            res = await axios.post(SERVER_URL + "/api/statistics/bydaygender", sendData, config);
            this.loadGenders(from, to);     
        }else if(this.props.header === 'ByPercentage'){
            res = await axios.post(SERVER_URL + "/api/statistics/bydaypercentage", sendData, config);
            this.loadPercentages(from, to);
        }

        const data = res.data.data;

        this.props.changeDataStatistic(data);
        this.props.changeLoadingState(false);
    }

    

    async loadStatisticsByHour(from=moment(moment.now()).subtract(1, 'days'), to=moment(moment.now()).subtract(1, 'days')){
        this.props.changeLoadingState(true);
        from = (moment(from).format('YYYY-MM-DD') + ' 00:00:00')
        to = (moment(to).format('YYYY-MM-DD') + ' 23:59:59')
        // from = '2021-05-17 00:00:00';
        // to = '2021-05-17 23:59:59';
        const SERVER_URL = `https://swapper.media/public/app`;

        
        const config = {
            headers: { Authorization: `Bearer `+localStorage.getItem('accessToken'), 'Content-Type': 'multipart/form-data', "type": "formData"}
        };

        var sendData = new FormData();
        sendData.append("email", localStorage.getItem('emailPartner'));
        sendData.append("watermark_id", this.props.statisticsWatermark);
        sendData.append("from", from.toString());
        sendData.append("to", to.toString());
        if(this.state.countrySelected !== ''){
            sendData.append("country", this.state.countrySelected);
        }
        if(this.state.citySelected !== 'none'){
            sendData.append("city", this.state.citySelected);
            this.props.changeIsSelectedCity(true);
        }else{
            this.props.changeIsSelectedCity(false);
        }

        

        if(this.state.percentageSelected !== ''){
            sendData.append("percentage", this.state.percentageSelected);   
            this.props.changeIsSelectedPercentage(true); 
        }else{
            this.props.changeIsSelectedPercentage(false);
        }
    
        if(this.state.genderSelected !== ''){
            sendData.append("gender", this.state.genderSelected);   
            this.props.changeIsSelectedGender(true); 
        }else{
            this.props.changeIsSelectedGender(false);
        }
    
        var res;

        if(this.props.header === 'ByTime'){
            res = await axios.post(SERVER_URL + "/api/statistics/byhour", sendData, config);
        }else if(this.props.header === 'ByLocation'){
            res = await axios.post(SERVER_URL + "/api/statistics/byhourubication", sendData, config);
        }else if(this.props.header === 'ByGender'){
            res = await axios.post(SERVER_URL + "/api/statistics/byhourgender", sendData, config);
            this.loadGenders(from, to);
        }else if(this.props.header === 'ByPercentage'){
            res = await axios.post(SERVER_URL + "/api/statistics/byhourpercentage", sendData, config);
            this.loadPercentages(from, to);

        }

        const data = res.data.data;
        this.props.changeDataStatistic(data);
        this.props.changeLoadingState(false);
    }

    async loadCountriesByDate(from=moment(moment.now()).subtract(30, 'days'), to=moment(moment.now()).subtract(1, 'days')) {
        
        from = (moment(from).format('YYYY-MM-DD') + ' 00:00:00')
        to = (moment(to).format('YYYY-MM-DD') + ' 23:59:59')
             
        const config = {
            headers: { Authorization: `Bearer `+localStorage.getItem('accessToken'), 'Content-Type': 'multipart/form-data', "type": "formData"}
        };
        var sendData = new FormData();

        sendData.append("email", localStorage.getItem('emailPartner'));
        sendData.append("watermark_id", this.props.statisticsWatermark);
        sendData.append("from", from.toString());
        sendData.append("to", to.toString());

        if(this.state.countrySelected !== ''){
            sendData.append("country", this.state.countrySelected);
        }
        if(this.state.citySelected !== 'none'){
            sendData.append("city", this.state.citySelected);
        }

        const SERVER_URL = `https://swapper.media/public/app`;

        const res = await axios.post(SERVER_URL + "/api/statistics/getUbicationByDay", sendData, config)

        const data = res.data.data;
        let countries = [];
        Object.keys(data).forEach(keys => {
            countries.push(keys);
        });
        this.setState({ countries: countries, dataLocation:data });
    }
    async loadCountriesByHour(from=moment(moment.now()).subtract(1, 'days')) {
        
        const from_search = (moment(from).format('YYYY-MM-DD') + ' 00:00:00')
        const to_search = (moment(from).format('YYYY-MM-DD') + ' 23:59:59')
             
        const config = {
            headers: { Authorization: `Bearer `+localStorage.getItem('accessToken'), 'Content-Type': 'multipart/form-data', "type": "formData"}
        };
        var sendData = new FormData();

        sendData.append("email", localStorage.getItem('emailPartner'));
        sendData.append("watermark_id", this.props.statisticsWatermark);
        sendData.append("from", from_search.toString());
        sendData.append("to", to_search.toString());

        if(this.state.countrySelected !== ''){
            sendData.append("country", this.state.countrySelected);
        }
        if(this.state.citySelected !== 'none'){
            sendData.append("city", this.state.citySelected);
        }

        const SERVER_URL = `https://swapper.media/public/app`;

        const res = await axios.post(SERVER_URL + "/api/statistics/byhourubication", sendData, config)

        const data = res.data.data;
        console.log(data);
        let countries = [];
        let dataLocation = {}
        Object.values(data).forEach(function(element){
            if(!dataLocation.hasOwnProperty(element['country'])){
                dataLocation[element['country']] = {}
                countries.push(element['country']);
            }
            if(!dataLocation[element['country']].hasOwnProperty(element['city'])){
                // let city = element['city'];
                dataLocation[element['country']][element['city']]={"city": element['city'], "country": element['country']};
            }
        });
        // Object.keys(data).forEach(keys => {
        //     countries.push(keys);
        // });
        this.setState({ countries: countries, dataLocation:dataLocation });
    }

    async loadGenders(from=moment(moment.now()).subtract(30, 'days'), to=moment(moment.now()).subtract(1, 'days')){
        from = (moment(from).format('YYYY-MM-DD') + ' 00:00:00')
        to = (moment(to).format('YYYY-MM-DD') + ' 23:59:59')
             
        const config = {
            headers: { Authorization: `Bearer `+localStorage.getItem('accessToken'), 'Content-Type': 'multipart/form-data', "type": "formData"}
        };
        var sendData = new FormData();
        sendData.append("email", localStorage.getItem('emailPartner'));
        sendData.append("watermark_id", this.props.statisticsWatermark);
        sendData.append("from", from.toString());
        sendData.append("to", to.toString());
        
        const SERVER_URL = `https://swapper.media/public/app`;


        var res = '';
        if(this.state.startDate === ''){

            res = await axios.post(SERVER_URL + "/api/statistics/byhourgender", sendData, config);

        }else{

            if(this.state.startDate.diff(this.state.endDate, 'days') === 0){
        
                res = await axios.post(SERVER_URL + "/api/statistics/byhourgender", sendData, config);
        
            }else{
                
                res = await axios.post(SERVER_URL + "/api/statistics/bydaygender", sendData, config);
        
            }
        }
        


        const data = res.data.data;
        var genders = [];
        data.forEach(element => {
            if(!genders.includes(element['gender'])){
                genders.push(element['gender']);
            }
        });
        this.setState({genders: genders});
      
    }
    
    async loadPercentages(from=moment(moment.now()).subtract(30, 'days'), to=moment(moment.now()).subtract(1, 'days')){
        from = (moment(from).format('YYYY-MM-DD') + ' 00:00:00')
        to = (moment(to).format('YYYY-MM-DD') + ' 23:59:59')
             
        const config = {
            headers: { Authorization: `Bearer `+localStorage.getItem('accessToken'), 'Content-Type': 'multipart/form-data', "type": "formData"}
        };
        var sendData = new FormData();
        sendData.append("email", localStorage.getItem('emailPartner'));
        sendData.append("watermark_id", this.props.statisticsWatermark);
        sendData.append("from", from.toString());
        sendData.append("to", to.toString());
        
        const SERVER_URL = `https://swapper.media/public/app`;


        var res = '';
        if(this.state.startDate === ''){

            res = await axios.post(SERVER_URL + "/api/statistics/byhourpercentage", sendData, config);

        }else{

            if(this.state.startDate.diff(this.state.endDate, 'days') === 0){
        
                res = await axios.post(SERVER_URL + "/api/statistics/byhourpercentage", sendData, config);
        
            }else{
                
                res = await axios.post(SERVER_URL + "/api/statistics/bydaypercentage", sendData, config);
        
            }
        }
        


        const data = res.data.data;
        var percentages = [];
        data.forEach(element => {
            if(!percentages.includes(element['percentage'])){
                percentages.push(element['percentage']);
            }
        });
        this.setState({percentages: percentages});
      
    }

    handleCountryChange(countrySelected){


        if(countrySelected.target.value !== ''){
            let cities = [];

            for (const city in this.state.dataLocation[countrySelected.target.value]){
                cities.push(city);
            }
                
            this.setState({countrySelected:countrySelected.target.value, cities: cities, citySelected: 'none'});
        }else{
            
            this.setState({countrySelected:countrySelected.target.value, cities:'', citySelected: 'none'});
        }
        if(document.getElementById('ciudad')){

            document.getElementById('ciudad').value = 'none';
        }
        
    }

    handleGenderChange(genderSelected){
        this.setState({genderSelected:genderSelected.target.value});
    }
    
    handlePercentageChange(percentageSelected){
        this.setState({percentageSelected:percentageSelected.target.value});
    }
    
    handleCityChange(citySelected){
        this.setState({citySelected:citySelected.target.value});
    }

    render(){
        const headerByTime = this.props.header === 'ByTime';
        const headerByLocation = this.props.header === 'ByLocation';
        const headerByGender = this.props.header === 'ByGender'; 
        // const headerByPercentage = this.props.header === 'ByPercentage'; 
        const isCountry = this.state.countrySelected !== '';
        const warningByTime = this.state.warningByTime;
        return(
            <div className="row justify-content-start header-statistics p-2 pt-4">
               <div className="col-12">
                   <div className="row justify-content-start">
                       <div className="col-12 justify-content-start">

                            <p className="color-text-white h2 text-md-left text-center">{this.props.statisticsWatermarkName}</p>
                       </div>
                   </div>
                   <div className="row justify-content-start">

                <div className="col-2 col-lg-1 pr-0">
                {/* <div> */}
                <Menu menuButton={<MenuButton className="button-menu"><span className="icon-white"><i className="fas fa-bars fa-2x"></i></span></MenuButton>}
                styles={{
                    backgroundColor: '#333333',
                    color: 'white',
                    boxShadow: 'none',
                    hover: {
                        backgroundColor: '#333333',
                    },
                    active: {
                        backgroundColor: '#333333',
                    }
                }}
                onChange={this.reloadStatistics}
                >
                    { headerByTime ? (<MenuItem onClick={(e) => {this.props.changeStatistic('ByTime');}} styles={{
                        backgroundColor: '#333333',
                        color: '#ff1580'
                    }}>Tiempo</MenuItem>) :(<MenuItem onClick={(e) =>{this.props.changeStatistic('ByTime');}} styles={{
                        backgroundColor: '#333333'
                    }}>Tiempo</MenuItem>)}
                    
                    { headerByLocation ?
                        (<MenuItem onClick={(e) => {this.props.changeStatistic('ByLocation');}} styles={{
                            backgroundColor: '#333333',
                            color: '#ff1580'
                        }}>Ubicación</MenuItem>):
                        (<MenuItem onClick={(e) =>  {this.props.changeStatistic('ByLocation'); }} styles={{
                            backgroundColor: '#333333'
                        }}>Ubicación</MenuItem>)
                    }
                    
                    { headerByGender ?
                        (<MenuItem onClick={(e) => {this.props.changeStatistic('ByGender');}} styles={{
                            backgroundColor: '#333333',
                            color: '#ff1580'
                        }}>Género</MenuItem>):
                        (<MenuItem onClick={(e) =>  {this.props.changeStatistic('ByGender'); }} styles={{
                            backgroundColor: '#333333'
                        }}>Género</MenuItem>)
                    }
                    {/* { headerByPercentage ?
                        (<MenuItem onClick={(e) => {this.props.changeStatistic('ByPercentage');}} styles={{
                            backgroundColor: '#333333',
                            color: '#ff1580'
                        }}>Porcentaje</MenuItem>):
                        (<MenuItem onClick={(e) =>  {this.props.changeStatistic('ByPercentage'); }} styles={{
                            backgroundColor: '#333333'
                        }}>Porcentaje</MenuItem>)
                    } */}
                </Menu>
                </div>
                <div className="col-lg-9 col-8">

                    <div className="row justify-content-start">
                        <div className="col-12">
                            <div className="d-flex justify-content-center justify-content-md-start">
                                {headerByTime? (
                                    
                                    <p className="h3 color-text-white">Estadísticas por tiempo</p>
                                    ):(null)}
                                {headerByLocation? (
                                    
                                    <p className="h3 color-text-white">Estadísticas por ubicación</p>
                                    ):(null)}
                                {headerByGender? (
                                    
                                    <p className="h3 color-text-white">Estadísticas por género</p>
                                    ):(null)}
                                {/* {headerByPercentage? (
                                    
                                    <p className="h3 color-text-white">Estadísticas por Porcentaje de escucha</p>
                                ):(null)} */}
                            </div>
                        </div>
                        {headerByTime ? 
                            (
                            <div className="col-12">
                                <div className="ml-2 row align-items-center justify-content-start d-none d-md-flex">
                                    <div className="col-auto p-0 mr-3">

                                        <DateRangePicker
                                            initialSettings={{ startDate: new Date(), endDate: new Date(), maxDate:new Date() }}
                                            onApply={this.handleApplyChange}
                                            // initialSettings={{maxDate:new Date()}}
                                            >
                                            <button className="btn shadow-none btn-filters">Fecha</button>
                                        </DateRangePicker>
                                        {warningByTime && 
                                                this.alertDateRangeExceed()
                                            }
                                    </div>
                                </div>
                                <div className="d-block d-md-none">

                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-12 d-block">

                                                    <DateRangePicker
                                                        initialSettings={{ startDate: new Date(), endDate: new Date(), maxDate:new Date() }}
                                                        onApply={this.handleApplyChange}
                                                        // initialSettings={{maxDate:new Date()}}
                                                        >
                                                        <button className="btn shadow-none btn-filters w-100 d-block">Fecha</button>
                                                    </DateRangePicker>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {warningByTime && 
                                        this.alertDateRangeExceed()
                                    }
                                </div>
                            </div>
                        ): null}
                        {headerByLocation ? 
                        (
                            <div>

                                <div className="ml-2 row align-items-center justify-content-start d-none d-md-flex">
                                    <div className="col-auto p-0 mr-3">
                                        
                                        <DateRangePicker
                                        initialSettings={{ startDate: new Date(), endDate: new Date(), autoApply: true, maxDate: new Date() }}
                                        onApply={this.handleApplyDateRange}
                                        // initialSettings={{maxDate:new Date()}}
                                        >
                                            <button className="btn shadow-none btn-filters">Fecha</button>
                                        </DateRangePicker>
                                    </div>
                                    
                                    <div className="col-auto p-0">
                                        
                                        <select id='pais' name='pais' onChange={this.handleCountryChange} className='selector-country form-control'>
                                            <option value="">País</option>
                                            {this.state.countries.length > 0 &&
                                                this.state.countries.map((item) => (
                                                    <option value={item}>{item}</option>
                                                    ))}
                                        </select>
                                    </div>
                                    {isCountry ? (
                                        <div className="col-auto">
                                        <select id='ciudad' name='ciudad' onChange={this.handleCityChange} className='selector-country form-control'>
                                            <option value='none'>Ciudad</option>
                                            {this.state.cities.length > 0 &&
                                                this.state.cities.map((item) => (
                                                    <option value={item}>{item}</option>
                                                ))}
                                        </select>
                                        </div>
                                    ) : null}
                                    <div className="col-2">
                                        <button className='btn shadow-none btn-search' onClick={(e) => this.handleSearchByLocation()}>Buscar</button>
                                    </div>
                                    {warningByTime && 
                                        this.alertDateRangeExceed()
                                    }
                                </div>
                                <div className="d-block d-md-none">
                                    <div className="row">

                                        <div className="col-12 mb-2">
                                            <div className="row">
                                                <div className="col-12">
                                                    <DateRangePicker
                                                    initialSettings={{ startDate: new Date(), endDate: new Date(), autoApply: true, maxDate: new Date() }}
                                                    onApply={this.handleApplyDateRange}
                                                    // initialSettings={{maxDate:new Date()}}
                                                    >
                                                        <button className="btn shadow-none btn-filters w-100">Fecha</button>
                                                    </DateRangePicker>
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div className="col-12 mb-2">
                                            <div className="row">
                                                <div className="col-12">
                                            
                                            <select id='pais' name='pais' onChange={this.handleCountryChange} className='selector-country form-control  text-align-last-center'>
                                                <option value="">País</option>
                                                {this.state.countries.length > 0 &&
                                                    this.state.countries.map((item) => (
                                                        <option value={item}>{item}</option>
                                                        ))}
                                            </select>
                                                </div>
                                            </div>
                                        </div>

                                        {isCountry ? (
                                            <div className="col-12 mb-2">
                                                <div className="row">
                                                    <div className="col-12">
                                            <select id='ciudad' name='ciudad' onChange={this.handleCityChange} className='selector-country form-control  text-align-last-center'>
                                                <option value='none'>Ciudad</option>
                                                {this.state.cities.length > 0 &&
                                                    this.state.cities.map((item) => (
                                                        <option value={item}>{item}</option>
                                                    ))}
                                            </select>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                        <div className="col-12 mb-2">
                                            <div className="row">
                                                <div className="col-12">
                                            <button className='btn shadow-none btn-search w-100' onClick={(e) => this.handleSearchByLocation()}>Buscar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {warningByTime && 
                                        this.alertDateRangeExceed()
                                    }
                                </div>
                            </div>
                        ) : null
                        
                        }
                        {headerByGender ? 
                        (
                            <div>
                                <div className="ml-2 row align-items-center justify-content-start d-none d-md-flex">
                                    <div className="col-auto p-0 mr-3 ">
                                        
                                        <DateRangePicker
                                        initialSettings={{ startDate: new Date(), endDate: new Date(), autoApply: true, maxDate: new Date() }}
                                        onApply={this.handleApplyDateRange}
                                        // initialSettings={{maxDate:new Date()}}
                                        >
                                            <button className="btn shadow-none btn-filters">Fecha</button>
                                        </DateRangePicker>
                                    </div>
                                    
                                    <div className="col-auto p-0">
                                        
                                        <select id='genero' name='genero' onChange={this.handleGenderChange} className='selector-country form-control'>
                                            <option value="">Género</option>
                                            {this.state.genders.length > 0 &&
                                                this.state.genders.map((item) => (
                                                    <option value={item}>{item}</option>
                                                    ))}
                                        </select>
                                    </div>

                                    <div className="col-2">
                                        <button className='btn shadow-none btn-search' onClick={(e) => this.handleSearchByGender()}>Buscar</button>
                                    </div>
                                    {warningByTime && 
                                        this.alertDateRangeExceed()
                                    }
                                </div>
                                <div className="d-block d-md-none">
                                    <div className="row">

                                        <div className="col-12 mb-2">

                                            <div className="row">

                                                <div className="col-12">
                                                
                                                    <DateRangePicker
                                                    initialSettings={{ startDate: new Date(), endDate: new Date(), autoApply: true, maxDate: new Date() }}
                                                    onApply={this.handleApplyDateRange}
                                                    // initialSettings={{maxDate:new Date()}}
                                                    >
                                                        <button className="btn shadow-none btn-filters w-100">Fecha</button>
                                                    </DateRangePicker>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-12 mb-2">

                                            <div className="row">

                                                <div className="col-12">
                                                    
                                                    <select id='genero' name='genero' onChange={this.handleGenderChange} className='selector-country form-control text-align-last-center'>
                                                        <option value="">Género</option>
                                                        {this.state.genders.length > 0 &&
                                                            this.state.genders.map((item) => (
                                                                <option value={item}>{item}</option>
                                                                ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-2">

                                            <div className="row">

                                                <div className="col-12">
                                                    <button className='btn shadow-none btn-search w-100' onClick={(e) => this.handleSearchByGender()}>Buscar</button>
                                                </div>
                                            </div>
                                        </div>

                                        {warningByTime && 
                                            this.alertDateRangeExceed()
                                        }
                                    </div>
                                </div>
                            </div>

                            
                        )
                        : null}
                        {/* {headerByPercentage ? 
                        (
                            <div>
                                <div className="ml-2 row align-items-center justify-content-start d-none d-md-flex">
                                    <div className="col-auto p-0 mr-3">
                                        
                                        <DateRangePicker
                                        initialSettings={{ startDate: new Date(), endDate: new Date(), autoApply: true, maxDate: new Date() }}
                                        onApply={this.handleApplyDateRange}
                                        // initialSettings={{maxDate:new Date()}}
                                        >
                                            <button className="btn shadow-none btn-filters">Fecha</button>
                                        </DateRangePicker>
                                    </div>
                                    
                                    <div className="col-auto p-0">
                                        
                                        <select id='percentage' name='percentage' onChange={this.handlePercentageChange} className='selector-country form-control'>
                                            <option value="">Porcentaje</option>
                                            {this.state.percentages.length > 0 &&
                                                this.state.percentages.map((item) => (
                                                    <option value={item}>{item}</option>
                                                    ))}
                                        </select>
                                    </div>

                                    <div className="col-2">
                                        <button className='btn shadow-none btn-search' onClick={(e) => this.handleSearchByPercentage()}>Buscar</button>
                                    </div>
                                    {warningByTime && 
                                        this.alertDateRangeExceed()
                                    }
                                </div>
                                <div className="d-block d-md-none">
                                    <div className="row">

                                        <div className="col-12 mb-2">

                                            <div className="row">

                                                <div className="col-12">
    
                                                    
                                                    <DateRangePicker
                                                    initialSettings={{ startDate: new Date(), endDate: new Date(), autoApply: true, maxDate: new Date() }}
                                                    onApply={this.handleApplyDateRange}
                                                    // initialSettings={{maxDate:new Date()}}
                                                    >
                                                        <button className="btn shadow-none btn-filters w-100">Fecha</button>
                                                    </DateRangePicker>
                                                
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 mb-2">


                                            <div className="row">

                                                <div className="col-12">
                                                    <select id='percentage' name='percentage' onChange={this.handlePercentageChange} className='selector-country form-control text-align-last-center'>
                                                        <option value="">Porcentaje</option>
                                                        {this.state.percentages.length > 0 &&
                                                            this.state.percentages.map((item) => (
                                                                <option value={item}>{item}</option>
                                                                ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 mb-2">
                                            <div className="row">

                                                <div className="col-12">

                                                    <button className='btn shadow-none btn-search w-100' onClick={(e) => this.handleSearchByPercentage()}>Buscar</button>
                                                </div>
                                            </div>
                                        </div>
                                        {warningByTime && 
                                            this.alertDateRangeExceed()
                                        }
                                    </div>
                                </div>
                            </div>

                        )
                        : null} */}
                    </div>
                </div>
                
                <div className="col-md-2 col-sm-12">

                    {/* <div className="col-12 p-0"> */}
                        <div className="row align-items-stretch justify-content-end boton-descargar-lista">
                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="btn shadow-none btn-download-list mr-3"
                                table="download-this"
                                filename="lista de escuchas"
                                sheet="lista de escuchas"
                                buttonText='Descargar lista'
                                />
                        {/* </div> */}
                    </div>
                </div>
                   </div>
                </div> 

            </div>
        )
    }
}
export default HeaderStatistics