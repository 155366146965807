import React from 'react';
import HeaderStatistics from './HeaderStatistics';
import StatisticsByTime from './StatisticsByTime';
import StatisticsByLocation from './StatisticsByLocation';
import StatisticsByGender from './StatisticsByGender';
import StatisticsByPercentage from './StatisticsByPercentage';
import './Statistics.css';


class Statistics extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            header: 'ByTime',
            dataStatistics: [
                {
                  fecha: '23-03-2020',
                  escuchas: 30,
                  usuarios: 15,
                  pais: 'Venezuela',
                  ciudad: 'Caracas'
                },
                {
                  fecha: '24-03-2020',
                  escuchas: 30,
                  usuarios: 15,
                  pais: 'Venezuela',
                  ciudad: 'Maracay'
                },
                {
                  fecha: '25-03-2020',
                  escuchas: 30,
                  usuarios: 15,
                  pais: 'Venezuela',
                  ciudad: 'Maracay'
                },
                {
                  fecha: '26-03-2020',
                  escuchas: 20,
                  usuarios: 5,
                  pais: 'Venezuela',
                  ciudad: 'Maracay'
                },
                {
                  fecha: '27-03-2020',
                  escuchas: 54,
                  usuarios: 31,
                  pais: 'Venezuela',
                  ciudad: 'Caracas'
                },
                {
                  fecha: '28-03-2020',
                  escuchas: 41,
                  usuarios: 6,
                  pais: 'Colombia',
                  ciudad: 'Bogotá'
                },
                {
                  fecha: '29-03-2020',
                  escuchas: 50,
                  usuarios: 9,
                  pais: 'Colombia',
                  ciudad: 'Bogotá'
                },
                {
                  fecha: '30-03-2020',
                  escuchas: 20,
                  usuarios: 10,
                  pais: 'Colombia',
                  ciudad: 'Medellín'
                },
                {
                  fecha: '01-04-2020',
                  escuchas: 59,
                  usuarios: 24,
                  pais: 'Colombia',
                  ciudad: 'Medellín'
                },
                {
                  fecha: '02-03-2020',
                  escuchas: 25,
                  usuarios: 12,
                  pais: 'Venezuela',
                  ciudad: 'Caracas'
                },
                {
                  fecha: '03-03-2020',
                  escuchas: 30,
                  usuarios: 10,
                  pais: 'Venezuela',
                  ciudad: 'Maracay'
                },
                {
                  fecha: '04-03-2020',
                  escuchas: 30,
                  usuarios: 10,
                  pais: 'Colombia',
                  ciudad: 'Bogotá'
                },
                {
                  fecha: '05-03-2020',
                  escuchas: 30,
                  usuarios: 10,
                  pais: 'Venezuela',
                  ciudad: 'Caracas'
                },
              ],
            loading: true,
            dateRangeType: 'byHour',
            isSelectedCity: false,
            country: '',
            gender: '',
            isSelectedGender: false,
            percentage: '',
            isSelectedPercentage: false
        };
        this.changeStatistic = this.changeStatistic.bind(this);
        this.changeDataStatistic = this.changeDataStatistic.bind(this);
        this.changeLoadingState = this.changeLoadingState.bind(this);
        this.changeDateRangeType = this.changeDateRangeType.bind(this);
        this.changeIsSelectedCity = this.changeIsSelectedCity.bind(this);
        this.changeIsSelectedGender = this.changeIsSelectedGender.bind(this);
        this.changeIsSelectedPercentage = this.changeIsSelectedPercentage.bind(this);
        this.changePercentage = this.changePercentage.bind(this);
        this.changeCountry = this.changeCountry.bind(this);
        this.changeGender = this.changeGender.bind(this);
    }

    changeStatistic(statisticToShow){
        this.setState({header: statisticToShow})
    }
    changeDataStatistic(dataStatistics){
        this.setState({dataStatistics: dataStatistics})
    }
    changeLoadingState(loading){
      this.setState({loading: loading})
    }
    changeDateRangeType(dateRangeType){
      this.setState({dateRangeType:dateRangeType});
    }
    changeIsSelectedCity(isSelectedCity){
      this.setState({isSelectedCity:isSelectedCity});
    }
    changeCountry(country){
      this.setState({country:country})
    }
    changePercentage(percentage){
      this.setState({percentage:percentage})
    }
    changeGender(gender){
      this.setState({gender:gender});
    }
    changeIsSelectedGender(isSelectedGender){
      this.setState({isSelectedGender:isSelectedGender});
    }
    changeIsSelectedPercentage(isSelectedPercentage){
      this.setState({isSelectedPercentage:isSelectedPercentage});
    }
    render(){
        const dataStatistics = this.state.dataStatistics;
        const isByLocation = this.state.header === 'ByLocation';
        const isByTime = this.state.header === 'ByTime';
        const isByGender = this.state.header === 'ByGender';
        const isByPercentage = this.state.header === 'ByPercentage';
        const isLoading = this.state.loading;
        const {dateRangeType, isSelectedCity, country, gender, isSelectedGender, percentage, isSelectedPercentage} = this.state;
        // console.log(this.props.statisticsWatermarkName);
        return(
                <div className="margin-top-80-px">
                
                <div className="row justify-content-center align-items-center py-md-4 my-md-4 large-height">
                    <div className="col-12 col-md-8">
                        <HeaderStatistics statisticsWatermarkName={this.props.statisticsWatermarkName} changeIsSelectedPercentage={this.changeIsSelectedPercentage} changePercentage={this.changePercentage} changeGender={this.changeGender} changeIsSelectedGender={this.changeIsSelectedGender} changeCountry={this.changeCountry} changeIsSelectedCity={this.changeIsSelectedCity} changeDateRangeType={this.changeDateRangeType} statisticsWatermark={this.props.statisticsWatermark} changeLoadingState={this.changeLoadingState} changeStatistic={this.changeStatistic} changeDataStatistic={this.changeDataStatistic} header={this.state.header} />
                        {isLoading ? (
                          <div className="row justify-content-center align-items-center min-size">
                            <img alt="Loader" src="../img/swapper/loader.gif" /> 
                          </div>)
                          :( <div>
                            { isByTime ? <StatisticsByTime dateRangeType={dateRangeType} dataStatistics={dataStatistics}/> : null}
                            { isByLocation ? <StatisticsByLocation country={country} isSelectedCity={isSelectedCity} dateRangeType={dateRangeType} dataStatistics={dataStatistics}/> : null}
                            { isByGender ? <StatisticsByGender gender={gender} isSelectedGender={isSelectedGender} dateRangeType={dateRangeType} dataStatistics={dataStatistics}/> : null}
                            { isByPercentage ? <StatisticsByPercentage percentage={percentage} isSelectedPercentage={isSelectedPercentage} dateRangeType={dateRangeType} dataStatistics={dataStatistics}/> : null}
                          </div>)}
                    </div>
                </div>
                    
                </div>);
    }


}

export default Statistics;