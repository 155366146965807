import React from 'react';
import Chart from 'react-google-charts';


class StatisticsByGender extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            colors: null,
        };
    }

  

    render(){
        const escuchas = [];
        const generos = [];
        let rows = [];
        for (const [index, value] of this.props.dataStatistics.entries()){
            console.log(value);
            const date = new Date(value.register_at); 
            let dateString;
            let fullDateString; 
            if(this.props.dateRangeType === 'byHour'){
                dateString = date.getUTCHours().toString();
                dateString = (dateString+":00")
                fullDateString = date.getUTCDate().toString()  + '/' +(date.getUTCMonth()+1).toString() + '/' +  date.getFullYear().toString()+' '+ dateString;

            }else{
                dateString = date.getUTCDate().toString()  + '/' +(date.getUTCMonth()+1).toString() + '/' +  date.getFullYear().toString();
                fullDateString = dateString;
            }
            escuchas.push(value.number_listeners);
            if(!generos.includes(value.gender)){
                generos.push(value.gender)
            }


            rows.unshift(
                <tr key={index}>
                    <th>{fullDateString}</th>
                    <th>{value.gender}</th>
                    <th>{value.number_users}</th>
                    <th>{value.number_listeners}</th>
                </tr>
            )

        }
        let dataGenero = []
        let dataParaMostrar='';
        let dataMostrarGoogle = [['Género', 'Escuchas']];
        if(this.props.gender === ''){

            for (let index = 0; index < generos.length; index++) {
                dataGenero.push(0);
                
            }
            for (const [, value] of this.props.dataStatistics.entries()){
                dataGenero[generos.indexOf(value.gender)] = dataGenero[generos.indexOf(value.gender)] + value.number_listeners;
            }
            dataParaMostrar = generos;
        }
        console.log(dataParaMostrar);
        console.log(dataGenero);
        for (let index = 0; index < dataGenero.length; index++) {
            const genero = dataGenero[index];
            const data = dataParaMostrar[index];
            dataMostrarGoogle.push([data, genero]);
        }
        console.log(dataMostrarGoogle);
        const isData = this.props.dataStatistics.length >0;
        const isSelectedGender = this.props.isSelectedGender;

        return(
            <div>
                {isData ?(

                    <div>
                        <div className="row justify-content-center align-items-center py-4">

                            {!isSelectedGender &&
                                <div className="col-12 p-0">

                                    <Chart
                                        width={'105%'}
                                        height={'400px'}
                                        chartType="PieChart"
                                        loader={<div>Cargando gráfico</div>}
                                        data={dataMostrarGoogle}
                                        options={{
                                            title: 'Estadísticas por género',
                                            backgroundColor: '#f3f2f2',
                                        }}
                                        rootProps={{ 'data-testid': '1' }}
                                    />

                                </div> 
                                }
                        </div>

                        <div className="table-responsive-md">

                            <table id="download-this" className="table table-striped">
                                    <thead className="table-head">
                                        <tr>
                                            <th>Fecha</th>
                                            <th>Género</th>
                                            <th># Usuarios</th>
                                            <th># Escuchas</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows}
                                    </tbody>
                            </table>
                        </div>
                    </div>
                ): (
                    <div className="row justify-content-center align-items-center min-size">
                        <div className="h1">
                            No hay escuchas disponibles
                        </div>
                    </div>
                )}
            </div>
        );
    }


}

export default StatisticsByGender;