import React, { Component } from "react";

class main extends Component {
    render() {
        return (
            <div>
                <section class="hero-section" id="hero-thankiu">
                    <div class="container">
                    </div>
                </section>
                <section className="hero-section-thankiu pt-0 pt-md-5 " id="hero-thankiu-content">
                    <div className="container position-relative height-thanks-girl">
                    <div className="container mt-5">
                        <div className="row">
                            <div className='col-12 col-md-9 mt-0 mt-md-5'>
                                <h2 className='py-3 nunito text-center text-md-left'>¡Gracias por darnos tu información!</h2>
                                </div>
                            <div className='col-12 col-md-6'>
                                <h5 className='roboto px-4 px-md-0 text-center text-md-left'>Te contactaremos lo mas pronto posible</h5>
                                <h5 className='roboto text-center text-md-left'>Conoce mas sobre nosotros en nuestras redes sociales</h5>
                                <div className="col-12 offset-md-5 col-sm-7 col-md-7 mt-md-5">
                                    <p className="redes-thanks text-center text-md-right my-4 mt-md-2">
                                        <a href="#"><img class='px-1' alt="RRSS" src="img/swapper/RRSS_gradient-1.svg" width="55" /></a>
                                        <a href="https://www.linkedin.com/swappermedia"><img class='px-1' alt="Linkedin Swapper" src="img/swapper/RRSS_gradient-2.svg" width="55" /></a>
                                        <a href="https://www.facebook.com/swappermedia"><img class='px-1' alt="Facebook Swapper" src="img/swapper/RRSS_gradient-3.svg" width="55" /></a>
                                        <a href="https://www.instagram.com/swappermedia/"><img class='px-1' alt="Instagram Swapper" src="img/swapper/RRSS_gradient-4.svg" width="55" /></a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-5 d-none d-md-block chica-thanks"><img className="w-100" alt="mujer gracias" src="img/swapper/mujer-thanks.png" /></div>
                    <div className="col-12 d-block d-md-none "><img className="w-100" alt="mujer" src="img/swapper/mujer-sinfondo.png" /></div>
                    </div>

                </section>

            </div>

        );
    }
}

export default main;