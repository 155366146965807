import React from 'react';
import './Header.css'
import {Link} from 'react-router-dom';
class Header extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            profile: false,
            watermarks: false,
        }
        if (this.props.location === 'profile'){
            this.state.profile = true;
        }else if (this.props.location === 'watermarks'){
            this.state.watermarks = true;
        }
        this.handleGoToWatermarks = this.handleGoToWatermarks.bind(this);
    }

    handleClose(){
        localStorage.clear();
        window.location ='/login';
    }

    handleGoToProfile(){
        window.location = '/profile';
    }
    handleGoToWatermarks(){
        window.location = '/watermarks' 
    }
    componentDidMount(){
        if(localStorage.getItem('emailPartner') ==null && localStorage.getItem('accessToken')==null){
            window.location ='/login';
        }
    }

    render(){
        const profile = this.state.profile;
        const watermarks = this.state.watermarks;
         
        return(
            <div id='menuLogin' className="fixed-top">
                <div className="site-mobile-menu site-navbar-target">
                    <div className="site-mobile-menu-header">
                        <div className="site-mobile-menu-close mt-3">
                            <span className="icofont-close js-menu-toggle"></span>
                        </div>
                    </div>
                    <div className="site-mobile-menu-body"></div>
                </div>
                <header className="site-navbar" role="banner">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-6 col-lg-2">
                                <h1 className="mb-0 site-logo">
                                    <Link to="/profile" className="mb-0">
                                        <img src="img/swapper/swapper-logo-2.svg" alt="Swapper logo"/>
                                    </Link>
                                </h1>
                            </div>
                            <div className="col-12 col-md-10 d-none d-lg-block">
                                <nav className="site-navigation position-relative text-right" role="navigation">

                                    <ul className="site-menu main-menu js-clone-nav mr-auto d-none d-lg-block">
                                        <li>{profile ? <Link to="/profile" onClick={this.handleGoToProfile}   className="nav-link highlighted">Perfil</Link>:
                                            <Link to="/profile" onClick={this.handleGoToProfile}   className="nav-link">Perfil</Link>}
                                        </li>
                                        <li>{ watermarks ? <Link to="/watermarks" onClick={this.handleGoToWatermarks} className="nav-link highlighted">Swappies</Link>:
                                            <Link to="/watermarks" onClick={this.handleGoToWatermarks} className="nav-link">Swappies</Link>}
                                        </li>
                                        <li><Link to="/login" className="nav-link" onClick={this.handleClose} >Log out</Link></li>
                                    </ul>
                                </nav>
                            </div>

                            <div className="col-6 d-inline-block d-lg-none ml-md-0 py-3">

                                <a href="#" className="burger site-menu-toggle js-menu-toggle burger-background-pink" data-toggle="collapse" data-target="#main-navbar">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        )
    }

}

export default Header;