import React from 'react';
import Header from '../header/Header';
import Footer from '../landing/footer';
import axios from "axios";
import './Profile.css';
class Profile extends React.Component{

    constructor(props){
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleListMarks = this.handleListMarks.bind(this);
        this.state = {
            sendMark: true,
            loaderProcess: false,
            messageResponse: '',
            loading: true,
            form: {
                name: '',
                //date: '',
                audioType: '',
                description: '',
                file: ''
            },
            error: null,
            watermarksNotAvailable: false,
            data: "",    
            errors: {
                name: '',
                //date: '',
                audioType: '',
                description: '',
                file: ''
            },
            
        }
        this.watermarksAvailable();

    }
    async watermarksAvailable(){
        this.setState({loading: true});
        const SERVER_URL = `https://swapper.media/public/app`;
        const config = {
            headers: { Authorization: `Bearer `+localStorage.getItem('accessToken')}
        };
        
        const res = await axios.post(SERVER_URL + "/api/partner/checkUser",{
            email : localStorage.getItem('emailPartner'),
        }, config)
        const watermarksNotAvailable = res.data.disponible === 0;
        this.setState({loading: false, watermarksNotAvailable: watermarksNotAvailable})
    }

    handleChange = event => {
        let form = this.state.form;
        form[event.target.name] = event.target.value;
      
        this.setState({
          form
        });
        this.validate();

    }

    handleListMarks(){
        window.location= '/watermarks';   
    }
    
    handleSubmit(event){
        event.preventDefault();
        console.log(event.target[3].files[0]);
        if(this.validate()){

            this.setState({loaderProcess : true})

            const SERVER_URL = `https://swapper.media/public/app/api`;

            const config = {
                headers: { Authorization: `Bearer `+localStorage.getItem('accessToken'), 'Content-Type': 'multipart/form-data', "type": "formData"}
            };

            var data = new FormData();
            data.append("email", localStorage.getItem('emailPartner'));
            data.append("title", event.target[0].value);
            data.append("audioType", event.target[1].value);
            data.append("description", event.target[2].value);
            data.append("file", event.target[3].files[0]);
            
            axios.post(SERVER_URL + "/partner/newWatermark", data , config)
            .then(response => {
                if(response.data.code === 200){
                    this.setState({ messageResponse: "Su swappy esta siendo procesada"});
                    let form = {};
                    form["name"] = "";
                    form["audioType"] = "";
                    form["file"] = "";
                    form["description"] = "";
                    this.setState({form:form});
                    this.setState({ loaderProcess : false });
                    this.setState({ sendMark: false });
                    event.target.reset();
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({ messageResponse: "Ocurrio un error con el swappy", loaderProcess:false});
            });

        }

    } 

    validate(){
        let form = this.state.form;
        let errors = {};
        let isValid = true;
        if (!form["name"]) {
            isValid = false;
            errors["name"] = "Campo requerido.";
        }else if(form["name"].length > 30){
            isValid = false;
            errors["name"] = "El nombre no puede tener más de 30 caracteres.";
        }else{
            errors["name"] = ""; 
        }
        if (form["audioType"] === '0') {
            isValid = false;
            errors["audioType"] = "Campo requerido.";
        }else{
            errors["audioType"] = ""; 
        }
        /*if (!form["date"]) {
            isValid = false;
            errors["date"] = "Campo requerido.";
        }else{
            errors["date"] = ""; 
        }*/
        if (!form["description"]) {
            isValid = false;
            errors["description"] = "Campo requerido.";
        }else{
            errors["description"] = ""; 
        }
        if (!form["file"]) {
            isValid = false;
            errors["file"] = "Campo requerido.";
        }else{
            errors["file"] = ""; 
        }

        this.setState({
            errors: errors
          });
      
          return isValid;
    }
    render(){
        const {loading, watermarksNotAvailable} = this.state;
        return(
            <div>
                <Header></Header>
                { this.state.loaderProcess ? (
                <div className="loaderProcess"><div><img alt="Loader" src="../img/swapper/loader.gif" /></div></div> 
                ): null }
                <div className="container">
                    
                    {loading ? (
                        <div className="row justify-content-center align-items-center min-size">
                            <img alt="Loader" src="../img/swapper/loader.gif" /> 
                        </div>
                    ) : (
                        
                        <div className="row justify-content-center align-items-center formWatermarks mb-5 mt-13">
                        {watermarksNotAvailable ? (
                           <div className="row ">
                               <div className="col-auto">
                               <p className="h1">No puede registrar más swappies.</p>
                               <p className="h1">Llegó al límite de swappies que puede crear.</p>
                                   </div>
                           </div> 
                        ) : (
                                <div class="col-12 col-md-6 col-lg-4 m-auto marcoFormulario">
                                {this.state.sendMark ? (
                                <div>
                                    <form name='addWatermarks' id='addWatermarks' method='POST' onSubmit={this.handleSubmit}>
                                <div className="row">
                                    <div className="col-10 text-left">
                                        Crear swappy
                                    </div>
                                    <div class="text-right cursor-hand font-weight-bold" onClick={this.handleListMarks}>X</div>

                                    <div className="col-12 my-3">
                                        <input onChange={this.handleChange} type="text" name="name" className="form-control" placeholder="Nombre"></input>
                                        <div className="text-danger">{this.state.errors.name}</div>

                                    </div>
                                    {/*<div className="col-12 my-3">
                                        <input onChange={this.handleChange} type="date" name="date" className="form-control" placeholder="Fecha de creacion"></input>
                                        <div className="text-danger">{this.state.errors.date}</div>
                                    </div>*/}
                                    <div className="col-12 my-3">
                                        <select className="form-control" placeholder="Tipo de audio" name="audioType" onChange={this.handleChange}>
                                            <option value="0">Seleccione</option>
                                            <option value="1">Canción</option>
                                            <option value="2">Comercial</option>
                                            <option value="3">ID Radio</option>
                                        </select>
                                        <div className="text-danger">{this.state.errors.audioType}</div>

                                    </div>
                                    <div className="col-12 my-3">
                                        <input type="text" onChange={this.handleChange} className="form-control" name="description" placeholder="Descripcion"></input>
                                        <div className="text-danger">{this.state.errors.description}</div>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <div className="p-4 fileSpace">
                                        <label>Adjuntar Archivo</label>
                                        <input type="file" onChange={this.handleChange}  name="file" accept=".mp3,audio/*"></input></div>
                                    </div>
                                    <div className="col-12 p-0 mb-3">
                                    <div className="text-danger d-block">{this.state.errors.file}</div>
                                    </div>
                                    
                                    <div className="col-12 my-3 text-right">
                                        <button type='Submit' className="btn buttonContinue">Crear</button>
                                        <div className="d-block">{this.state.messageResponse}</div>
                                    </div>
                                </div>
                            </form>
                            </div>
                            ) : (
                            <div class="my-2">
                                <div class="mb-5 mt-13">
                                <b>Tu Swappy esta siendo procesada</b>
                                <div class="px-4 my-4">Ha sido incorporada a tu lista de Swappies</div>
                                </div>
                                <div>
                                    <button onClick={this.handleListMarks} className="btn btnColor btn-success">Swappies</button>
                                </div>
                        </div>
                        )}
                            </div>
                        )}
                        </div>
                    )}  
                </div>
                <Footer>
                </Footer>
            </div>
        );
    }
}

export default Profile;
