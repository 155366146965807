import React, { Component } from "react";

class header extends Component {
    render() {
        return (
            <div>
                <header id="menu-thanks" className="site-navbar my-1 my-md-5 site-navbar-target" role="banner">

                    <div className="container">
                        <div className="row align-items-center">

                            <div className="col-12 col-md-6 col-lg-2">
                                <h1 className="mb-0 site-logo"><a className="mb-0" href="/"><img alt="Swapper Logo" src="img/swapper/swapper-logo-1.svg" width="250"/></a></h1>
                            </div>
                        </div>
                    </div>

                </header>

            </div>

        );
    }
}

export default header;