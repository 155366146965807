import React from 'react';
import Chart from 'react-google-charts';

class StatisticsByLocation extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            colors: null,
        };

    }



    render(){
        const escuchas = [];
        const paises = [];
        const ciudades = [];
        let rows = [];

        console.log(this.props.dataStatistics);

        for (const key in this.props.dataStatistics) {
            if (Object.hasOwnProperty.call(this.props.dataStatistics, key)) {
                const element = this.props.dataStatistics[key];
                const date = new Date(element.register_at);
                let dateString;
                let fullDateString; 

                if(this.props.dateRangeType === 'byHour'){
                    dateString = date.getUTCHours().toString();
                    dateString = (dateString+":00")
                    fullDateString = date.getUTCDate().toString()  + '/' +(date.getUTCMonth()+1).toString() + '/' +  date.getFullYear().toString()+' '+ dateString;
    
                }else{
                    dateString = date.getUTCDate().toString()  + '/' +(date.getUTCMonth()+1).toString() + '/' +  date.getFullYear().toString();
                    fullDateString = dateString;
                }
                escuchas.push(element.number_listeners);
                if(!paises.includes(element.country)){
                    paises.push(element.country)
                }
                if(this.props.country!== ''){
    
                    if(!ciudades.includes(element.city)){
                        ciudades.push(element.city)
                    }
                }

                rows.unshift(
                    <tr key={key}>
                        <th>{fullDateString}</th>
                        <th>{element.number_listeners}</th>
                        <th>{element.country}</th>
                        <th>{element.city}</th>
                    </tr>
                )
            }
        }

        let dataUbicacion = []
        let dataParaMostrar='';
        let dataMostrarGoogle = [['Ubicación', 'Escuchas']];
        if(this.props.country === ''){

            for (let index = 0; index < paises.length; index++) {
                dataUbicacion.push(0);
                
            }
            for (const key in this.props.dataStatistics) {
                if (Object.hasOwnProperty.call(this.props.dataStatistics, key)) {
                    const element = this.props.dataStatistics[key];

                    dataUbicacion[paises.indexOf(element.country)] = dataUbicacion[paises.indexOf(element.country)] + element.number_listeners;
                }
            }
            dataParaMostrar = paises;
        }else{
            for (let index = 0; index < ciudades.length; index++) {
                dataUbicacion.push(0);
                
            }
           
            for (const key in this.props.dataStatistics) {
                if (Object.hasOwnProperty.call(this.props.dataStatistics, key)) {
                    const element = this.props.dataStatistics[key];

                    dataUbicacion[ciudades.indexOf(element.city)] = dataUbicacion[ciudades.indexOf(element.city)] + element.number_listeners;
                }
            }
            dataParaMostrar = ciudades;

        }
        for (let index = 0; index < dataUbicacion.length; index++) {
            const ubicacion = dataUbicacion[index];
            const data = dataParaMostrar[index];
            dataMostrarGoogle.push([data, ubicacion]);
        }
        const isData = this.props.dataStatistics.length >0;
        const isSelectedCity = this.props.isSelectedCity;
        return(
            <div>
                {isData ?(

                    <div>
                        <div className="row justify-content-center align-items-center py-4">
                            {!isSelectedCity &&
                                <div className="col-12 p-0">


                                    <Chart
                                        width={'105%'}
                                        height={'400px'}
                                        chartType="PieChart"
                                        loader={<div>Cargando gráfico</div>}
                                        data={dataMostrarGoogle}
                                        options={{
                                            title: 'Estadísticas por ubicacion',
                                            backgroundColor: '#f3f2f2',

                                        }}
                                        rootProps={{ 'data-testid': '1' }}
                                    />


                                </div>
                                }
                        </div>
                        <div className="table-responsive-md">
                            <table id="download-this" className="table table-striped">
                                    <thead className="table-head">
                                        <tr>
                                            <th>Fecha</th>
                                            <th># Escuchas</th>
                                            <th>País</th>
                                            <th>Ciudad</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows}
                                    </tbody>
                            </table>
                        </div>
                    </div>
                ): (
                    <div className="row justify-content-center align-items-center min-size">
                        <div className="h1">
                            No hay escuchas disponibles
                        </div>
                    </div>
                )}
            </div>
        );
    }


}

export default StatisticsByLocation;