import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Login from "./components/login/App";
import Landing from "./components/landing/Landing";
import Contact from "./components/sendContact/index";
import Profile from './components/profile/Profile';
import Upload from './components/profile/Upload';
import Watermarks from './components/watermarks/Watermarks';
import {Terms} from './components/terms/Terms';
import { Privacy } from './components/privacy/Privacy';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route exact path="/terms">
          <Terms />
        </Route>
        <Route exact path="/privacy">
          <Privacy />
        </Route>
        <Route path="/thanks">
          <Contact />
        </Route>
        <Route exact path="/profile">
          <Profile />
        </Route>
        <Route exact path="/upload">
          <Upload />
        </Route>
        <Route exact path="/watermarks">
          <Watermarks />
        </Route>
        <Route exact path="/">
          <Landing />
        </Route>
      </Switch>
  </BrowserRouter>
  </div>
  );
}

export default App;