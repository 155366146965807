import React from 'react';
import Header from '../header/Header';
import Footer from '../landing/footer';
import WatermarksList from './WatermarksList';
import WatermarksNoList from './WatermarksNoList';
import Statiscs from '../statistics/Statistics';
import axios from "axios";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment-timezone';

import './Watermarks.css';

class Watermarks extends React.Component{
    constructor(){
        super();
        this.state = {
            currentPage: 1,
            dataPerPage: 20,
            upperPageBound: 3,
            lowerPageBound: 0,
            isPrevBtnActive: 'disabled',
            isNextBtnActive: '',
            pageBound: 3,
            data: [],
            loading: true,
            searchData: '',
            statistics: false,
            statisticsWatermark: '',
            statisticsWatermarkName: '',
            isFiltered: false,
            dataFiltered: '',
            dateRangeFilter:{
              startDate: '',
              endDate: '',
            }
          }
          this.handleClick = this.handleClick.bind(this);
          this.btnDecrementClick = this.btnDecrementClick.bind(this);
          this.btnIncrementClick = this.btnIncrementClick.bind(this);
          this.btnNextClick = this.btnNextClick.bind(this);
          this.btnPrevClick = this.btnPrevClick.bind(this);
          this.handleStatisticsClick = this.handleStatisticsClick.bind(this);
          this.setFilterByDateRange = this.setFilterByDateRange.bind(this);
          this.filterWatermarks = this.filterWatermarks.bind(this);
          this.clearButton = this.clearButton.bind(this);
          this.handleSearchChange = this.handleSearchChange.bind(this);
          this.loadWatermarks();
    }

    handleCreateNew(){
      window.location = '/upload';   
    }

    componentDidUpdate() {
      // document.getElementById(this.state.currentPage).classList.add('active')
      // $("ul li.active").removeClass('active');
      // $('ul li#'+this.state.currentPage).addClass('active');
    }

    handleClick(event) {
      let listid = Number(event.target.id);
      document.getElementById(this.state.currentPage).classList.remove('active');
      this.setState({
        currentPage: listid
      });
      document.getElementById(this.state.currentPage).classList.add('active');
      // $("ul li.active").removeClass('active');
      // $('ul li#'+listid).addClass('active');
      this.setPrevAndNextBtnClass(listid);
    }

    handleStatisticsClick(id, name){
      // console.log(id);
      this.setState({
        statistics: true,
        statisticsWatermark: id,
        statisticsWatermarkName: name 
      })
    }

    handleSearchChange(searchData){
      this.setState({
        searchData: searchData.target.value
      });
    }

    setPrevAndNextBtnClass(listid) {
      let totalPage = Math.ceil(this.state.data.length / this.state.dataPerPage);
      this.setState({isNextBtnActive: 'disabled'});
      this.setState({isPrevBtnActive: 'disabled'});
      if(totalPage === listid && totalPage > 1){
          this.setState({isPrevBtnActive: ''});
      }
      else if(listid === 1 && totalPage > 1){
          this.setState({isNextBtnActive: ''});
      }
      else if(totalPage > 1){
          this.setState({isNextBtnActive: ''});
          this.setState({isPrevBtnActive: ''});
      }else if(totalPage === 1 && listid === 1){
        this.setState({isNextBtnActive: 'disabled'});
      }
    }

    btnIncrementClick() {
      this.setState({upperPageBound: this.state.upperPageBound + this.state.pageBound});
      this.setState({lowerPageBound: this.state.lowerPageBound + this.state.pageBound});
      let listid = this.state.upperPageBound + 1;
      this.setState({ currentPage: listid});
      this.setPrevAndNextBtnClass(listid);
    }
    btnDecrementClick() {
      this.setState({upperPageBound: this.state.upperPageBound - this.state.pageBound});
      this.setState({lowerPageBound: this.state.lowerPageBound - this.state.pageBound});
      let listid = this.state.upperPageBound - this.state.pageBound;
      this.setState({ currentPage: listid});
      this.setPrevAndNextBtnClass(listid);
    }
    btnPrevClick() {
        if((this.state.currentPage -1)%this.state.pageBound === 0 ){
            this.setState({upperPageBound: this.state.upperPageBound - this.state.pageBound});
            this.setState({lowerPageBound: this.state.lowerPageBound - this.state.pageBound});
        }
        let listid = this.state.currentPage - 1;
        this.setState({ currentPage : listid});
        this.setPrevAndNextBtnClass(listid);
    }
    btnNextClick() {
        if((this.state.currentPage +1) > this.state.upperPageBound ){
            this.setState({upperPageBound: this.state.upperPageBound + this.state.pageBound});
            this.setState({lowerPageBound: this.state.lowerPageBound + this.state.pageBound});
        }
        let listid = this.state.currentPage + 1;
        this.setState({ currentPage : listid});
        this.setPrevAndNextBtnClass(listid);
    }


    async loadWatermarks(){
        this.setState({loading: true});
        const SERVER_URL = `https://swapper.media/public/app`;
        const config = {
            headers: { Authorization: `Bearer `+localStorage.getItem('accessToken')}
        };
        
        const res = await axios.post(SERVER_URL + "/api/partner/watermarks",{
            email : localStorage.getItem('emailPartner'),
        }, config)
        const data = res.data.data;
        this.setState({data:data.reverse(), loading: false});
    }
    filterWatermarks(){
      const data = this.state.data;
      let searchData = this.state.searchData;
      let newDataDateRange = [];
      let newDataSearch = [];
      let newData = [];
      console.log(searchData);
      for (const watermark of data) {
        if(this.state.dateRangeFilter.startDate !== ''){
          if(moment(watermark.created_at).isBetween(this.state.dateRangeFilter.startDate, this.state.dateRangeFilter.endDate)){
            newDataDateRange.push(watermark);
          }
        }
        if(searchData!==''){
          if(watermark.name.toLowerCase().includes(searchData.toLowerCase())){
            newDataSearch.push(watermark);
          }
        }
      }
      if(this.state.dateRangeFilter.startDate === ''){
        newDataDateRange = data;
      }
      if(searchData==='' ){
        newDataSearch = data;
      }

      if(searchData === '' && this.state.dateRangeFilter.startDate === '' ){
        newDataSearch = data;
        newDataDateRange = data;

      }

      for(const newDataR of newDataDateRange){
        for(const newDataS of newDataSearch){
          if(newDataR === newDataS){
            newData.push(newDataR);
          }
        }
      }

      this.setState({dataFiltered: newData, isFiltered: true, currentPage:1, startDate: '', finishDate: '', searchData: ''});
    }

    setFilterByDateRange(event, dates){

      this.setState({dateRangeFilter:{
        startDate:moment.tz(dates.startDate, 'America/Bogota').format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment.tz(dates.endDate, 'America/Bogota').format('YYYY-MM-DD HH:mm:ss')
      }})
      
    }

    clearButton(){
      window.location = '/watermarks';
    }

    render(){
        let data = []
        if(!this.state.isFiltered){
          data = this.state.data;
        }else{
          data = this.state.dataFiltered;
        }
        const { isFiltered, currentPage, dataPerPage,upperPageBound,lowerPageBound,isPrevBtnActive,isNextBtnActive, loading, statistics, statisticsWatermark, statisticsWatermarkName } = this.state;
        const isData = data.length >0
        const indexOfLastData = currentPage * dataPerPage;
        const indexOfFirstData = indexOfLastData - dataPerPage;
        const currentData = data.slice(indexOfFirstData, indexOfLastData);
        
        const renderData = currentData.map((data, index) => {
          return <li key={index}>{data}</li>;
        });
        
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(data.length / dataPerPage); i++) {
          pageNumbers.push(i);
        }

        const renderPageNumbers = pageNumbers.map(number => {
          if(number === currentPage){
              return(
                  <div className="pageBound-active">
                    <li key={number} className='active' id={number}><a href='#' id={number} onClick={this.handleClick}>{number}</a></li>
                  </div>
              )
          }
          else if((number < upperPageBound + 1) && number > lowerPageBound){
              return(
                <div className="pageBound-inactive">

                  <li key={number} id={number}><a href='#' id={number} onClick={this.handleClick}>{number}</a></li>
                </div>
              )
          }
      });
      
      let pageIncrementBtn = null;
      if(pageNumbers.length > upperPageBound){
          pageIncrementBtn = <li className=''><a href='#' onClick={this.btnIncrementClick}> &hellip; </a></li>
      }
      let pageDecrementBtn = null;
      if(lowerPageBound >= 1){
          pageDecrementBtn = <li className=''><a href='#' onClick={this.btnDecrementClick}> &hellip; </a></li>
      }
      let renderPrevBtn = null;
      if(isPrevBtnActive === 'disabled') {
          renderPrevBtn = <li className="disabled p-2"><span id="btnNext">Prev</span></li>
      }
      else{
          renderPrevBtn = <li className="p-2"><a href='#' id="btnPrev" onClick={this.btnPrevClick}> Prev </a></li>
      }
      let renderNextBtn = null;
      if(isNextBtnActive === 'disabled' || renderPageNumbers.length === 1) {
          renderNextBtn = <li className="disabled p-2"><span id="btnNext"> Next </span></li>
      }
      else{
          renderNextBtn = <li className="p-2"><a href='#' id="btnNext" onClick={this.btnNextClick}> Next </a></li>
      }

        return(
            <div>
                <Header location="watermarks" />
                {statistics ? (<Statiscs statisticsWatermark={statisticsWatermark} statisticsWatermarkName={statisticsWatermarkName} />) : (<div>
                  {loading ? (
                  <div className="row justify-content-center align-items-center min-size">
                    <img alt="Loader" src="../img/swapper/loader.gif" /> 
                  </div>
                  ): (
                  <div className="margin-top-80-px container">
                    
                    <div className="row align-items-center  justify-content-around">
                      <div className="col-lg-12 col-md-12 col-xl-12">
                        <div className="row align-items-center  justify-content-around" >

                          <div className="col-auto col-7 col-sm-6 col-md-6 col-lg-5 col-xl-3 p-2 align-items-start">
                            <div className="row align-items-center justify-content-md-left justify-content-lg-left justify-content-center">
                              <div className="col-7 col-md-5 col-lg-5 col-xl-5 px-0 pt-1">
                                <p className="watermark-title mb-0 ">Swappies</p>
                              </div>
                              <div className="col-lg-2 col-xl-2 col-md-1 col-sm-2 col-2 px-0 pt-2 align-items-center justify-content-center">
                                <img
                                    src="./img/swapper/icono-2.svg"
                                    alt="icono swappies"
                                  />
                              </div>
                            </div>
                          </div>
                          <div className="col-3 col-md-5 col-xl-6 d-none d-xl-block">
                            <div className="row align-items-center justify-content-center">
                              <DateRangePicker
                                    initialSettings={{ startDate: new Date(), endDate: new Date(), autoApply: true, maxDate: new Date() }}
                                    onApply={this.setFilterByDateRange}
                                    // initialSettings={{maxDate:new Date()}}
                                    id = 'daterange'
                                    >
                                <div className="col-auto">

                                  <button className="btn btn-filter-watermark">Fechas</button>
                                </div>
                        
                        
                              </DateRangePicker>
                              <div className="col-4 mx-2">

                                <input type="text" placeholder="Busque por nombre" onChange={this.handleSearchChange}/>
                              </div>
                              <div className="col-auto mx-2">
                                <button className="btn btn-filter-watermark" onClick={this.filterWatermarks}>
                                  <i className="fas fa-search"></i>
                                </button>
                              </div>
                              <div className="col-auto mx-2">

                                <button className="btn btn-filter-watermark" onClick={this.clearButton}>
                                    Limpiar
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-3 col-lg-4 col-md-4 col-5 col-xl-2">
                              <div className="row p-2 d-none d-md-inline-block">
                                  <button onClick={this.handleCreateNew} className="btnColor btn btn-success m-2">Crear Nueva</button>
                                  {/* <button onClick={this.handleCreateNew} className="btnColor btn btn-success m-2">Consultar</button> */}
                              </div>
                              <div className="row d-block d-md-none">
                                  <div className="">
                                    <img className="col-3 col-sm-2 p-0 mx-2" alt="simbolo más" onClick={this.handleCreateNew} src='./img/swapper/simbolo_mas.png'/>
                                    {/* <img className="col-3 col-sm-2 p-0 mx-2" alt="simbolo consulta" onClick={this.handleCreateNew} src='./img/swapper/simbolo_consulta.png'/> */}
                                  </div>
                              </div>
                          
                          </div>


                          <div className="col-12 col-md-10 d-block d-xl-none">
                            <div className="row align-items-center justify-content-center my-2">
                              <DateRangePicker
                                    initialSettings={{ startDate: new Date(), endDate: new Date(), autoApply: true, maxDate: new Date() }}
                                    onApply={this.setFilterByDateRange}
                                    // initialSettings={{maxDate:new Date()}}
                                    id = 'daterange2'
                                    >
                                    <div className="col-11 col-md-auto">

                                      <button className="btn m-2 btn-filter-watermark w-100">Fechas</button>
                                    </div>
                                </DateRangePicker>
                                <div className="col-11 col-md-4">
                                  <input className="w-100 m-2 text-center" type="text" placeholder="Busque por nombre" onChange={this.handleSearchChange}/>
                                </div>
                                <div className="col-11 col-md-auto">
                                  <button className="btn w-100 m-2 btn-filter-watermark" onClick={this.filterWatermarks}>
                                    <i className="fas fa-search"></i>
                                  </button>
                                </div>
                                <div className="col-11 col-md-auto">
                                  <button className="btn w-100 m-2 btn-filter-watermark" onClick={this.clearButton}>
                                      Limpiar
                                  </button>
                                </div>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  <div>
                        {isData ? 
                          <WatermarksList handleStatisticsClick={this.handleStatisticsClick} watermarks={renderData}/>: 
                          <div>
                            {isFiltered?(
                              <div className="row justify-content-center align-items-center large-height">
                                <p className="h1">No se han encontrado swappies</p>
                              </div>
                            ):(
                                <WatermarksNoList />
                              )}
                          </div>
                          }
                    </div>
                    {isData ? (
                      <div className="row justify-content-center align-items-center">
                        <div className="row justify-content-center col-3">
                          <ul className="pagination">
                            {renderPrevBtn}
                            {pageDecrementBtn}
                            {renderPageNumbers}
                            {pageIncrementBtn}
                            {renderNextBtn}
                          </ul>
                        </div>
                      </div>
                      ): null}
                  </div>  
                  )}
                </div>)}
                
                <Footer></Footer>
            </div>
        );
    }
}
export default Watermarks;