import React, { Component } from "react";

class footer extends Component {
    render() {
        return (
            <div>
                <footer className="footer" role="contentinfo">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 mb-4 mb-md-0" >
                            </div>
                            <div className="col-md-4 py-3">
                                <p className="copyright">&copy; Swapper 2021. Todos los derechos reservados</p>
                            </div>
                            <div className="col-md-4 mb-4 mb-md-0">
                            </div>
                        </div>
                    </div>
                </footer>
            </div>

        );
    }
}

export default footer;