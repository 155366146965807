import React, { Component } from "react";

class Footer extends Component {
    render() {
        return (
            <div>
                <footer className="footer" role="contentinfo">
                    <div className="container">
                        <div className="row py-2">
                            <div className="col-md-4 mb-4 mb-md-0 text-center" >
                                <p className="copyright py-3" >&copy; Swapper 2021. Todos los derechos reservados</p>
                            </div>
                            <div className="col-md-4 mb-4 mb-md-0">
                                <h1 className="mb-0 mx-auto site-logo text-center"><a href="#" className="mb-0"><img src="img/swapper/swapper-logo-1.svg" alt="Swapper Logo" width="200px" /></a></h1>
                            </div>
                            <div className="col-md-4 mb-4 mb-md-0">
                                <p className="text-center mt-1">
                                    <a href="#" className="px-1"><img src="img/swapper/icon1.png" alt="WhatsApp" width="35" /></a>
                                    <a href="https://www.linkedin.com/swappermedia" className="px-1"><img src="img/swapper/icon2.png" alt="Linkedin" width="35" /></a>
                                    <a href="https://www.facebook.com/swappermedia" className="px-1"><img src="img/swapper/icon3.png" alt="Facebook" width="35" /></a>
                                    <a href="https://www.instagram.com/swappermedia/" className="px-1"><img src="img/swapper/icon4.png" alt="Instagram" width="35" /></a>
                                </p>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>

        );
    }
}

export default Footer;