import React from 'react';
class WatermarksNoList extends React.Component{

    handleCreateNew(){
        window.location = '/upload';
    }

    render(){
        return(
            <div>
                
                    <div className="row align-items-center justify-content-center p-xl-5 m-xl-5 py-lg-5 my-lg-5 px-lg-4 my-lg-4 py-md-5 my-md-5 px-md-4 my-md-4 py-4 my-4 px-4 my-4">
                        <div onClick={this.handleCreateNew} className="cursor-hand create-first-watermark col-xl-4 col-lg-5 col-md-6 col-sm-7 col-8 py-4 px-6">
                            <img src="./img/swapper/07.png" alt="Icono dedo" className="mb-3"/>
                            <p className="m-0 font-weight-bold color-black font-size-bigger">Crea tu primer swappy</p>
                            <p>Y comienza a disfrutar de la experiencia</p>
                            <img alt="Icono más" src="./img/swapper/simbolo_mas.png" />
                        </div>
                    </div>
            </div>
        )
    }
}

export default WatermarksNoList;