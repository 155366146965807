import React from 'react';
import { Line } from 'react-chartjs-2';

class StatiscsByTime extends React.Component{
    render(){
        let fechas = [];
        let escuchas = [];
        let usuarios = [];
        const rows = [];
        let dataStatistics = [];
        // if(this.props.dateRangeType === 'byHour'){

        //     dataStatistics = this.props.dataStatistics;
        // }else{
            dataStatistics = this.props.dataStatistics.reverse();
        // }

        for (const [index, value] of this.props.dataStatistics.entries()){
            const date = new Date(value.register_at); 

            let dateString;
            let fullDateString; 
            if(this.props.dateRangeType === 'byHour'){
                dateString = date.getUTCHours().toString();
                dateString = (dateString+":00")
                fullDateString = date.getUTCDate().toString()  + '/' +(date.getUTCMonth()+1).toString() + '/' +  date.getFullYear().toString()+' '+ dateString;

            }else{
                dateString = date.getUTCDate().toString()  + '/' +(date.getUTCMonth()+1).toString() + '/' +  date.getFullYear().toString();
                fullDateString = dateString;
            }
            fechas.unshift(dateString);
            escuchas.unshift(value.number_listeners);
            usuarios.unshift(value.number_users);
            rows.push(
                <tr key={index}>
                    <th>{fullDateString}</th>
                    <th>{value.number_users}</th>
                    <th>{value.number_listeners}</th>
                </tr>
            )

        }
        const isData = this.props.dataStatistics.length >0;
        return(
            <div>

                {isData ? (
                    <div>
                        <div className="row justify-content-center align-items-center py-4">
                                    
                            <div className="col-8">

                                <Line 
                                    data = {{
                                        labels: fechas,
                                        datasets: [{
                                            label: 'Escuchas',
                                            data: escuchas,
                                            backgroundColor: ['rgba(254, 21, 128, 1)'],
                                            borderColor: ['rgba(254, 21, 128, 1)'],
                                            borderWidth: 5,
                                            pointBorderWidth: 10,

                                        },
                                        {
                                            label:'Usuarios',
                                            data: usuarios,
                                            backgroundColor: ['rgba(51, 51, 51, 1)'],
                                            borderColor: ['rgba(51, 51, 51, 1)'],
                                            borderWidth: 5,
                                            pointBorderWidth: 10,

                                        }]
                                    }}
                                    options = {{ 
                                        aspectRatio: 2,

                                        }}


                                />
                            </div>
                        </div>
                        <div className="table-responsive-md">
                            <table id="download-this" className="table table-striped">
                                    <thead className="table-head">
                                        <tr>
                                            <th>Fecha</th>
                                            <th># Usuarios</th>
                                            <th># Escuchas</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows}
                                    </tbody>
                            </table>
                        </div>
                    </div>
                ):(<div className="row justify-content-center align-items-center min-size">
                        <div className="h1">
                            No hay escuchas disponibles
                        </div>
                    </div>)}
            </div>

        );
    }
}

export default StatiscsByTime;