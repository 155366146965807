import React from "react";
import Header from "./header";
import Main from "./main";
import Footer from "./footer";
import "../landing/style.css"

export default function index() {
        return (
            <div>
              <Header></Header>
              <Main></Main>
              <Footer></Footer>
            </div>
        );
        
}

