import React from 'react';
import { Route, Switch,Link } from 'react-router-dom';

import Login from "../../components/login.component";
import "./style.css"

function login() {
  return (
    <div className="App">
      <nav className="d-none navbar navbar-expand-lg navbar-light fixed-top">
        <div className="container">
          <Link className="navbar-brand" to={"/sign-in"}>positronX.io</Link>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link className="nav-link" to={"/sign-in"}>Login</Link>
              </li>
              <li className="nav-item">
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div className="row">
      <div className="d-none d-md-block col-md-6 px-0">
        <img className="w-100" alt="Hombre inicio" src="img/fondo-hombre.png"></img>
      </div>
      <div className="col-12 col-md-6 px-0 viewLogin">
        <div className='mt-5 pt-5'>
        <a href="/"><img alt="Swapper Logo" src='img/swapper-logo.svg' width="220"></img></a>
        </div>
      <div className="auth-wrapper">
        <div className="auth-inner">
          <Switch>
            <Route exact path='/login' component={Login} />
          </Switch>
        </div>
      </div>
    </div>
    </div>
  </div>
  );
}

export default login;