import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../landing/footer';
import './privacy.css';

export const Privacy = () => {
  return (
    <div className='App privacy__app'>

            <div>
                <div className="site-mobile-menu site-navbar-target">
                    <div className="site-mobile-menu-header">
                        <div className="site-mobile-menu-close mt-3">
                            <span className="icofont-close js-menu-toggle"></span>
                        </div>
                    </div>
                    <div className="site-mobile-menu-body"></div>
                </div>
                <header className="site-navbar  js-sticky-header site-navbar-target terms__color-header" role="banner">

                    <div className="container">
                        <div className="row align-items-center">

                            <div className="col-6 col-lg-2">
                                <h1 className="mb-0 site-logo"><a href="/" className="mb-0"><img alt="Swapper Logo" src="img/swapper/swapper-logo-1.svg"/></a></h1>
                            </div>

                            <div className="col-12 col-md-10 d-none d-lg-block">
                                <nav className="site-navigation position-relative text-right" role="navigation">

                                    <ul className="site-menu main-menu js-clone-nav mr-auto d-none d-lg-block">
                                        <li><Link className="nav-link" to={"/"}>Página inicial</Link></li>
                                        <li><Link className="nav-link" to={"/login"}>Iniciar sesión</Link></li>
                                    </ul>
                                </nav>
                            </div>

                            <div className="col-6 d-inline-block d-lg-none ml-md-0 py-3">

                                <a href="#" className="burger site-menu-toggle js-menu-toggle" data-toggle="collapse" data-target="#main-navbar">
                                    <span></span>
                                </a>
                            </div>

                        </div>
                    </div>

                </header>
            </div>
    <div className='container privacy__container'>
                
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>
                <p className='privacy__p'>
                Swapper ("nosotros", "nuestro" o "nos") se compromete a proteger su privacidad. La presente Política de Privacidad explica cómo Swapper recoge, utiliza y divulga su información personal.<br /><br />

                Esta Política de Privacidad se aplica a nuestro sitio web, y a sus subdominios asociados (colectivamente, nuestro "Servicio") junto con nuestra aplicación, Swapper. Al acceder o utilizar nuestro Servicio, usted indica que ha leído, comprendido y aceptado la recopilación, el almacenamiento, el uso y la divulgación de su información personal tal y como se describe en esta Política de Privacidad y en nuestras Condiciones de Servicio.
                </p>
            </div>
        </div>
        <br />
        
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>
                <h3 className='privacy__h3'>Definiciones y términos clave</h3>
                <p className='privacy__p'>Para ayudar a explicar las cosas lo más claramente posible en esta Política de Privacidad, cada vez que se hace referencia a cualquiera de estos términos, se definen estrictamente como:
                </p>
                <ul className='privacy__ul'>
                    <li className='privacy__li'>Cookie: pequeña cantidad de datos generada por un sitio web y guardada por su navegador. Se utiliza para identificar su navegador, proporcionar análisis, recordar información sobre usted, como su preferencia de idioma o información de inicio de sesión.</li>
                    <li className='privacy__li'>Empresa: cuando en esta política se menciona "Empresa", "nosotros", "nos" o "nuestro", se refiere a Moth Technology, (752 west end ave apt 11c, Nueva York, NY.), que es responsable de su información según esta Política de Privacidad.</li>
                    <li className='privacy__li'>País: donde Swapper o los propietarios/fundadores de Swapper tienen su sede, en este caso es Estados Unidos.</li>
                    <li className='privacy__li'>Cliente: se refiere a la empresa, organización o persona que se registra para utilizar el Servicio Swapper para gestionar las relaciones con sus consumidores o usuarios del servicio</li>
                    <li className='privacy__li'>Dispositivo: cualquier dispositivo conectado a Internet, como un teléfono, una tableta, un ordenador o cualquier otro dispositivo que pueda utilizarse para visitar Swapper y utilizar los servicios.</li>
                    <li className='privacy__li'>Dirección IP: A cada dispositivo conectado a Internet se le asigna un número conocido como dirección de protocolo de Internet (IP). Estos números suelen asignarse en bloques geográficos. Una dirección IP puede utilizarse a menudo para identificar la ubicación desde la que un dispositivo se conecta a Internet.</li>
                    <li className='privacy__li'>Personal: se refiere a aquellas personas que son empleadas por Swapper o están bajo contrato para realizar un servicio en nombre de una de las partes.</li>
                    <li className='privacy__li'>Datos personales: cualquier información que directa o indirectamente, o en conexión con otra información -incluyendo un número de identificación personal- permite la identificación o identificabilidad de una persona física.</li>
                    <li className='privacy__li'>Servicio: se refiere al servicio prestado por Swapper tal y como se describe en las condiciones relativas (si están disponibles) y en esta plataforma.</li>
                    <li className='privacy__li'>Servicio de terceros: se refiere a los anunciantes, patrocinadores de concursos, socios promocionales y de marketing, y otros que proporcionan nuestro contenido o cuyos productos o servicios creemos que pueden interesarle.</li>
                    <li className='privacy__li'>Sitio web: El sitio de Swapper, al que se puede acceder a través de esta URL: <Link to={"/"}>www.swapper.media</Link></li>
                    <li className='privacy__li'>Usted: persona o entidad que se registra en Swapper para utilizar los Servicios. Swapper ("nosotros", "nuestro" o "nos") se compromete a proteger su privacidad. La presente Política de Privacidad explica cómo Swapper recoge, utiliza y divulga su información personal.</li>
                </ul>
            </div>
        </div>
        <br />
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>
                <h3 className='privacy__h3'>¿Qué información recogemos?</h3>
                <p className='privacy__p'>Recogemos información suya cuando visita nuestro sitio web/aplicación, se registra en nuestro sitio, realiza un pedido, se suscribe a nuestro boletín de noticias, responde a una encuesta o rellena un formulario.
                </p>

                <ul className='privacy__ul'>
                    <li className='privacy__li'>Nombre / Nombre de usuario</li>
                    <li className='privacy__li'>Números de teléfono</li>
                    <li className='privacy__li'>Direcciones de correo electrónico</li>
                    <li className='privacy__li'>Edad</li>
                    <li className='privacy__li'>Contraseña</li>
                </ul>
                <p className='privacy__p'>
                    También recopilamos información de los dispositivos móviles para una mejor experiencia de usuario, aunque estas funciones son completamente opcionales:
                </p>
              
                <ul className='privacy__ul'>
                    <li className='privacy__li'>Ubicación (GPS): Los datos de localización ayudan a crear una representación precisa de sus intereses, y esto puede utilizarse para ofrecer anuncios más específicos y relevantes a los clientes potenciales.</li>

                </ul>

                
            </div>
        </div>
        <br />
        
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>
                <h3 className='privacy__h3'>¿Cuándo utiliza Swapper información de usuarios finales de terceros?</h3>
                <p className='privacy__p'>Swapper recopilará los datos del usuario final necesarios para proporcionar los servicios de Swapper a nuestros clientes. Los usuarios finales pueden proporcionarnos voluntariamente la información que han puesto a disposición en los sitios web de las redes sociales. Si nos proporcionan dicha información, podemos recopilar la información disponible públicamente de los sitios web de las redes sociales que hayan indicado. Usted puede controlar la cantidad de información que los sitios web de las redes sociales hacen pública visitando estos sitios web y cambiando su configuración de privacidad.
                </p>
                
            </div>
        </div>
        <br />
        
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>
                <h3 className='privacy__h3'>¿Cuándo utiliza Swapper la información de los clientes procedente de terceros?</h3>
                <p className='privacy__p'>Recibimos cierta información de terceros cuando usted se pone en contacto con nosotros. Por ejemplo, cuando nos envía su dirección de correo electrónico para mostrar su interés en convertirse en cliente de Swapper, recibimos información de un tercero que proporciona servicios automatizados de detección de fraudes a Swapper. También recogemos ocasionalmente información que se hace pública en los sitios web de las redes sociales. Usted puede controlar la cantidad de información que los sitios web de las redes sociales hacen pública visitando estos sitios web y cambiando su configuración de privacidad.
                </p>
                
            </div>
        </div>
        <br />
        
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>
                <h3 className='privacy__h3'>¿Compartimos la información que recogemos con terceros?</h3>
                <p className='privacy__p'>
                        Podemos compartir la información que recopilamos, tanto personal como no personal, con terceros como anunciantes, patrocinadores de concursos, socios promocionales y de marketing, y otros que proporcionan nuestro contenido o cuyos productos o servicios creemos que pueden interesarle. También podemos compartirla con nuestras empresas afiliadas y socios comerciales actuales y futuros, y si estamos involucrados en una fusión, venta de activos u otra reorganización empresarial, también podemos compartir o transferir su información personal y no personal a nuestros sucesores en interés.
                        <br /> <br />
                        Podemos contratar a terceros proveedores de servicios de confianza para que realicen funciones y nos presten servicios, como el alojamiento y el mantenimiento de nuestros servidores y del sitio web/aplicación, el almacenamiento y la gestión de la base de datos, la gestión del correo electrónico, el marketing de almacenamiento, el procesamiento de tarjetas de crédito, el servicio de atención al cliente y el cumplimiento de los pedidos de productos y servicios que pueda adquirir a través del sitio web/aplicación. Es probable que compartamos su información personal, y posiblemente alguna información no personal, con estos terceros para que puedan realizar estos servicios para nosotros y para usted.
                        <br /> <br />
                        Es posible que compartamos parte de los datos de nuestros archivos de registro, incluidas las direcciones IP, con fines analíticos con terceros, como socios de análisis web, desarrolladores de aplicaciones y redes publicitarias. Si se comparte su dirección IP, puede utilizarse para estimar la ubicación general y otros datos técnicos como la velocidad de conexión, si ha visitado el sitio web/la aplicación en una ubicación compartida y el tipo de dispositivo utilizado para visitar el sitio web/la aplicación. Pueden agregar información sobre nuestra publicidad y lo que usted ve en el sitio web/la aplicación y luego proporcionar auditoría, investigación e informes para nosotros y nuestros anunciantes.
                        <br /> <br />
                        También podemos revelar información personal y no personal sobre usted a funcionarios del gobierno o de las fuerzas del orden o a partes privadas si, a nuestra entera discreción, lo consideramos necesario o apropiado para responder a reclamaciones, procesos legales (incluidas las citaciones), para proteger nuestros derechos e intereses o los de un tercero, la seguridad del público o de cualquier persona, para prevenir o detener cualquier actividad ilegal, no ética o legalmente procesable, o para cumplir de otro modo con las órdenes judiciales, leyes, normas y reglamentos aplicables.
                </p>
                
            </div>
        </div>
        <br />
        
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>
                <h3 className='privacy__h3'>¿Dónde y cuándo se recoge la información de los clientes y usuarios finales?</h3>
                <p className='privacy__p'>
                Swapper recogerá la información personal que usted nos envíe. También podemos recibir información personal sobre usted de terceros, como se ha descrito anteriormente.
                </p>
                
            </div>
        </div>
        <br />
        
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>
                <h3 className='privacy__h3'>¿Cómo utilizamos la información que recogemos?</h3>
                <p className='privacy__p'>
                    Toda la información que recopilamos de usted puede ser utilizada de una de las siguientes maneras:
                </p>
                
                <ul className='privacy__ul'>
                    <li className='privacy__li'>Para personalizar su experiencia (su información nos ayuda a responder mejor a sus necesidades individuales).</li>
                    <li className='privacy__li'>Para mejorar nuestro sitio web y nuestra aplicación (nos esforzamos continuamente por mejorar nuestra oferta de sitios web y aplicaciones basándonos en la información y los comentarios que recibimos de usted).</li>
                    <li className='privacy__li'>Para mejorar el servicio al cliente (su información nos ayuda a responder más eficazmente a sus solicitudes de servicio al cliente y a sus necesidades de apoyo).</li>
                    <li className='privacy__li'>Para procesar transacciones.</li>
                    <li className='privacy__li'>Para administrar un concurso, una promoción, una encuesta u otra función del sitio.</li>
                    <li className='privacy__li'>Para enviar correos electrónicos periódicos.</li>
                </ul>
            </div>
        </div>
        <br />
        
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>
                <h3 className='privacy__h3'>¿Cómo utilizamos su dirección de correo electrónico?</h3>
                <p className='privacy__p'>
                    Al enviar su dirección de correo electrónico en este sitio web o aplicación, usted acepta recibir nuestros correos electrónicos. Puede cancelar su participación en cualquiera de estas listas de correo electrónico en cualquier momento haciendo clic en el enlace de exclusión o en otra opción de cancelación de la suscripción que se incluye en el respectivo correo electrónico. Sólo enviamos correos electrónicos a personas que nos han autorizado a ponernos en contacto con ellas, ya sea directamente o a través de un tercero. No enviamos correos electrónicos comerciales no solicitados, porque odiamos el spam tanto como usted. Al enviar su dirección de correo electrónico, también acepta que utilicemos su dirección de correo electrónico para la segmentación de la audiencia de clientes en sitios como Facebook, donde mostramos publicidad personalizada a personas específicas que han optado por recibir comunicaciones de nosotros. Las direcciones de correo electrónico enviadas únicamente a través de la página de procesamiento de pedidos se utilizarán con el único propósito de enviarle información y actualizaciones relacionadas con su pedido. Sin embargo, si nos ha proporcionado el mismo correo electrónico a través de otro método, podremos utilizarlo para cualquiera de los fines indicados en esta Política. Nota: Si en algún momento desea darse de baja para no recibir futuros correos electrónicos, incluimos instrucciones detalladas para darse de baja en la parte inferior de cada correo electrónico.               
                </p>
            </div>
        </div>
        <br />
        
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>
                <h3 className='privacy__h3'>¿Cuánto tiempo conservamos su información?</h3>
                <p className='privacy__p'>
                    Conservamos su información sólo mientras la necesitamos para proporcionarle Swapper y cumplir con los fines descritos en esta política. Este es también el caso de cualquier persona con la que compartamos su información y que lleve a cabo servicios en nuestro nombre. Cuando ya no necesitemos utilizar su información y no haya necesidad de conservarla para cumplir con nuestras obligaciones legales o reglamentarias, la eliminaremos de nuestros sistemas o la despersonalizaremos para no poder identificarle.           
                </p>
            </div>
        </div>
        <br />
        
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>
                <h3 className='privacy__h3'>¿Cómo protegemos su información?</h3>
                <p className='privacy__p'>
                    Aplicamos una serie de medidas de seguridad para mantener la seguridad de su información personal cuando realiza un pedido o introduce, envía o accede a su información personal. Ofrecemos el uso de un servidor seguro. Toda la información sensible/de crédito suministrada se transmite a través de la tecnología Secure Socket Layer (SSL) y, a continuación, se encripta en la base de datos de nuestros proveedores de pasarelas de pago para que sólo puedan acceder a ella las personas autorizadas con derechos de acceso especiales a dichos sistemas, y que están obligadas a mantener la información confidencial. Después de una transacción, su información privada (tarjetas de crédito, números de la seguridad social, datos financieros, etc.) nunca se guarda en el archivo. Sin embargo, no podemos asegurar o garantizar la seguridad absoluta de cualquier información que usted transmita a Swapper o garantizar que su información en el Servicio no pueda ser accedida, revelada, alterada o destruida por una violación de cualquiera de nuestras salvaguardias físicas, técnicas o de gestión.
                </p>
            </div>
        </div>
        <br />
       
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>
                <h3 className='privacy__h3'>¿Puede mi información ser transferida a otros países?</h3>
                <p className='privacy__p'>
                    Swapper está constituida en Estados Unidos. La información recopilada a través de nuestro sitio web, a través de interacciones directas con usted, o por el uso de nuestros servicios de ayuda puede ser transferida de vez en cuando a nuestras oficinas o personal, o a terceros, ubicados en todo el mundo, y puede ser vista y alojada en cualquier parte del mundo, incluyendo países que pueden no tener leyes de aplicación general que regulen el uso y la transferencia de dichos datos. En la medida en que lo permita la legislación aplicable, al utilizar cualquiera de los elementos mencionados, usted consiente voluntariamente la transferencia transfronteriza y el alojamiento de dicha información.
                </p>
            </div>
        </div>
        <br />
  
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>
                <h3 className='privacy__h3'>¿Es segura la información recogida a través del Servicio Swapper?</h3>
                <p className='privacy__p'>
                    Tomamos precauciones para proteger la seguridad de su información. Disponemos de procedimientos físicos, electrónicos y de gestión para ayudar a salvaguardar, prevenir el acceso no autorizado, mantener la seguridad de los datos y utilizar correctamente su información. Sin embargo, ni las personas ni los sistemas de seguridad son infalibles, incluidos los sistemas de codificación. Además, las personas pueden cometer delitos intencionados, cometer errores o no seguir las políticas. Por lo tanto, aunque hacemos esfuerzos razonables para proteger su información personal, no podemos garantizar su seguridad absoluta. Si la legislación aplicable impone alguna obligación no exigible de proteger su información personal, usted acepta que la conducta indebida intencionada será el criterio utilizado para medir nuestro cumplimiento de esa obligación.
                </p>
            </div>
        </div>
        <br />
        
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>
                <h3 className='privacy__h3'>¿Puedo actualizar o corregir mi información?</h3>
                <p className='privacy__p'>
                    Los derechos que tiene para solicitar actualizaciones o correcciones de la información que Swapper recopila dependen de su relación con Swapper. El personal puede actualizar o corregir su información según se detalla en las políticas internas de empleo de nuestra empresa.
                    <br /> <br />
                    Los clientes tienen derecho a solicitar la restricción de ciertos usos y divulgaciones de información personal identificable de la siguiente manera. Puede ponerse en contacto con nosotros para (1) actualizar o corregir su información de identificación personal, (2) cambiar sus preferencias con respecto a las comunicaciones y otra información que reciba de nosotros, o (3) eliminar la información de identificación personal mantenida sobre usted en nuestros sistemas (con sujeción al párrafo siguiente), cancelando su cuenta. Dichas actualizaciones, correcciones, cambios y eliminaciones no tendrán ningún efecto sobre otra información que mantengamos, o sobre la información que hayamos proporcionado a terceros de acuerdo con esta Política de Privacidad antes de dicha actualización, corrección, cambio o eliminación. Para proteger su privacidad y seguridad, podemos tomar medidas razonables (como solicitar una contraseña única) para verificar su identidad antes de concederle acceso al perfil o realizar correcciones. Usted es responsable de mantener en secreto su contraseña única y la información de su cuenta en todo momento.
                    <br /> <br />
                    Debe ser consciente de que no es tecnológicamente posible eliminar de nuestro sistema todos y cada uno de los registros de la información que nos ha proporcionado. La necesidad de realizar copias de seguridad de nuestros sistemas para proteger la información de pérdidas involuntarias significa que puede existir una copia de su información en un formato no borrable que nos resultará difícil o imposible de localizar. Inmediatamente después de recibir su solicitud, toda la información personal almacenada en las bases de datos que utilizamos activamente y en otros medios de fácil búsqueda será actualizada, corregida, modificada o eliminada, según corresponda, tan pronto como sea razonable y técnicamente posible.
                    <br /> <br />
                    Si usted es un usuario final y desea actualizar, eliminar o recibir cualquier información que tengamos sobre usted, puede hacerlo poniéndose en contacto con la organización de la que es cliente.
                </p>
            </div>
        </div>
        <br />
        
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>
                <h3 className='privacy__h3'>Personal</h3>
                <p className='privacy__p'>
                    Si usted es un trabajador o solicitante de Swapper, recogemos la información que nos proporciona voluntariamente. Utilizamos la información recopilada con fines de Recursos Humanos para administrar las prestaciones a los trabajadores y seleccionar a los solicitantes.
                    <br /> <br />
                    Puede ponerse en contacto con nosotros para (1) actualizar o corregir su información, (2) cambiar sus preferencias con respecto a las comunicaciones y otra información que recibe de nosotros, o (3) recibir un registro de la información que tenemos relacionada con usted. Dichas actualizaciones, correcciones, cambios y supresiones no tendrán ningún efecto sobre otra información que mantengamos, o sobre la información que hayamos proporcionado a terceros de acuerdo con esta Política de Privacidad antes de dicha actualización, corrección, cambio o supresión.
                </p>
            </div>
        </div>
        <br />
        
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>
                <h3 className='privacy__h3'>Venta del negocio</h3>
                <p className='privacy__p'>
                Nos reservamos el derecho de transferir la información a un tercero en caso de venta, fusión u otra transferencia de todos o casi todos los activos de Swapper o de cualquiera de sus Afiliados Corporativos (tal y como se definen en el presente documento), o de la parte de Swapper o de cualquiera de sus Afiliados Corporativos con la que se relaciona el Servicio, o en caso de que interrumpamos nuestro negocio o presentemos una petición o se haya presentado contra nosotros una petición de quiebra, reorganización o procedimiento similar, siempre que el tercero acepte adherirse a los términos de esta Política de Privacidad.
                </p>
            </div>
        </div>
        <br />
        
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>
                <h3 className='privacy__h3'>Afiliados</h3>
                <p className='privacy__p'>
                Podemos revelar información (incluyendo información personal) sobre usted a nuestras filiales corporativas. A los efectos de la presente Política de Privacidad, se entiende por "Afiliado Corporativo" cualquier persona o entidad que directa o indirectamente controle, sea controlada o esté bajo el control común de Swapper, ya sea por propiedad o de otra manera. Cualquier información relativa a usted que proporcionemos a nuestras filiales corporativas será tratada por dichas filiales corporativas de acuerdo con los términos de esta política de privacidad.
                </p>
            </div>
        </div>
        <br />
        
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>
                <h3 className='privacy__h3'>Legislación aplicable</h3>
                <p className='privacy__p'>
                La presente Política de Privacidad se rige por las leyes de Estados Unidos, sin tener en cuenta su disposición sobre conflicto de leyes. Usted acepta la jurisdicción exclusiva de los tribunales en relación con cualquier acción o disputa que surja entre las partes en virtud de esta Política de Privacidad o en relación con ella, excepto para aquellas personas que puedan tener derecho a presentar reclamaciones en virtud del Escudo de Privacidad, o del marco suizo-estadounidense.
                <br /> <br />
                Las leyes de Estados Unidos, excluyendo sus conflictos de normas legales, regirán este Acuerdo y su uso del sitio web/la aplicación. Su uso del sitio web/aplicación también puede estar sujeto a otras leyes locales, estatales, nacionales o internacionales.
                <br/> <br/> 
                Al utilizar Swapper o ponerse en contacto con nosotros directamente, usted acepta esta Política de Privacidad. Si no está de acuerdo con esta Política de Privacidad, no debe participar en nuestro sitio web ni utilizar nuestros servicios. El uso continuado del sitio web, el contacto directo con nosotros o la publicación de cambios en esta Política de Privacidad que no afecten significativamente al uso o divulgación de su información personal significará que usted acepta dichos cambios.
                </p>
            </div>
        </div>
        <br />
        
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>
                <h3 className='privacy__h3'>Su consentimiento</h3>
                <p className='privacy__p'>
                Hemos actualizado nuestra política de privacidad para ofrecerle una total transparencia sobre lo que se establece cuando visita nuestro sitio y cómo se utiliza. Al utilizar nuestro Swapper, registrar una cuenta o realizar una compra, usted acepta nuestra Política de Privacidad y está de acuerdo con sus términos.
                </p>
            </div>
        </div>
        <br />
        
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>
                <h3 className='privacy__h3'>Enlaces a otros sitios web</h3>
                <p className='privacy__p'>
                    Esta Política de Privacidad se aplica únicamente a los Servicios. Los Servicios pueden contener enlaces a otros sitios web no operados ni controlados por Swapper. No somos responsables del contenido, la exactitud o las opiniones expresadas en dichos sitios web, y no investigamos, supervisamos o comprobamos la exactitud o integridad de los mismos. Recuerde que cuando utilice un enlace para ir de los Servicios a otro sitio web, nuestra Política de Privacidad ya no estará en vigor. Su navegación e interacción en cualquier otro sitio web, incluidos los que tienen un enlace en nuestra plataforma, está sujeta a las propias normas y políticas de ese sitio web. Estos terceros pueden utilizar sus propias cookies u otros métodos para recopilar información sobre usted.
                </p>
            </div>
        </div>
        <br />
        
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>
                <h3 className='privacy__h3'>Cookies</h3>
                <p className='privacy__p'>
                    Swapper utiliza "cookies" para identificar las áreas de nuestro sitio web que usted ha visitado. Una "cookie" es un pequeño fragmento de datos almacenado en su ordenador o dispositivo móvil por su navegador web. Utilizamos las cookies para mejorar el rendimiento y la funcionalidad de nuestro sitio web/aplicación, pero su uso no es esencial. Sin embargo, sin estas cookies, algunas funciones, como los vídeos, podrían dejar de estar disponibles o se le pediría que introdujera sus datos de acceso cada vez que visitara el sitio web o la aplicación, ya que no podríamos recordar que se había conectado previamente. La mayoría de los navegadores pueden desactivar el uso de cookies. Sin embargo, si desactiva las cookies, es posible que no pueda acceder a las funciones de nuestro sitio web correctamente o en absoluto. Nunca colocamos información de identificación personal en las cookies.
                </p>
            </div>
        </div>
        <br />
        
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>
                <h3 className='privacy__h3'>Bloqueo y desactivación de cookies y tecnologías similares</h3>
                <p className='privacy__p'>
                También puede configurar su navegador para bloquear las cookies y tecnologías similares, pero esta acción puede bloquear nuestras cookies esenciales e impedir que nuestro sitio web funcione correctamente, y es posible que no pueda utilizar plenamente todas sus funciones y servicios. También debe tener en cuenta que puede perder parte de la información guardada (por ejemplo, los datos de acceso guardados o las preferencias del sitio) si bloquea las cookies en su navegador. Los distintos navegadores ponen a su disposición diferentes controles. La desactivación de una cookie o de una categoría de cookies no elimina la cookie de su navegador, sino que deberá hacerlo usted mismo desde su navegador; para más información, visite el menú de ayuda de su navegador.
                </p>
            </div>
        </div>
        <br />
        
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>
                <h3 className='privacy__h3'>Privacidad de los niños</h3>
                <p className='privacy__p'>
                Recogemos información de niños menores de 13 años sólo para mejorar nuestros servicios. Si usted es un padre o tutor y tiene conocimiento de que su hijo nos ha proporcionado datos personales sin su permiso, póngase en contacto con nosotros. Si tenemos conocimiento de que hemos recopilado Datos Personales de cualquier persona menor de 13 años sin verificar el consentimiento de sus padres, tomaremos medidas para eliminar esa información de nuestros servidores.
                </p>
            </div>
        </div>
        <br />
        
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>
                <h3 className='privacy__h3'>Cambios en nuestra política de privacidad</h3>
                <p className='privacy__p'>
                    Podemos cambiar nuestro Servicio y nuestras políticas, y es posible que tengamos que hacer cambios en esta Política de Privacidad para que reflejen con exactitud nuestro Servicio y nuestras políticas. A menos que la ley exija lo contrario, le notificaremos (por ejemplo, a través de nuestro Servicio) antes de realizar cambios en esta Política de Privacidad y le daremos la oportunidad de revisarlos antes de que entren en vigor. Entonces, si sigue utilizando el Servicio, quedará obligado por la Política de Privacidad actualizada. Si no desea aceptar esta Política de Privacidad o cualquier otra actualizada, puede eliminar su cuenta.
                </p>
            </div>
        </div>
        <br />
        
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>
                <h3 className='privacy__h3'>Servicios de terceros</h3>
                <p className='privacy__p'>
                    Podemos mostrar, incluir o poner a disposición contenidos de terceros (incluyendo datos, información, aplicaciones y otros servicios de productos) o proporcionar enlaces a sitios web o servicios de terceros ("Servicios de terceros").
                    <br /> <br />
                    Usted reconoce y acepta que Swapper no será responsable de los Servicios de Terceros, incluyendo su exactitud, integridad, puntualidad, validez, cumplimiento de los derechos de autor, legalidad, decencia, calidad o cualquier otro aspecto de los mismos. Swapper no asume ni tendrá ninguna responsabilidad ante usted o cualquier otra persona o entidad por los Servicios de Terceros.
                    <br /> <br />
                    Los Servicios de Terceros y los enlaces a los mismos se facilitan únicamente para su comodidad y usted accede a ellos y los utiliza bajo su propia responsabilidad y sujeto a los términos y condiciones de dichos terceros.
                </p>
            </div>
        </div>
        <br />
        
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>
                <h3 className='privacy__h3'>Tecnologías de seguimiento</h3>
                <p className='privacy__p'>
                    API de Google Maps
                    <br /> <br />
                    La API de Google Maps es una sólida herramienta que puede utilizarse para crear un mapa personalizado, un mapa con capacidad de búsqueda, funciones de registro, visualización de datos en directo sincronizados con la ubicación, planificación de rutas o creación de un mashup, por nombrar algunas.
                    <br /> <br />
                    Google Maps API puede recopilar información de usted y de su dispositivo por motivos de seguridad.
                    <br /> <br />
                    Google Maps API recopila información que se mantiene de acuerdo con su Política de Privacidad.
                </p>
            </div>
        </div>
        <br />
        
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>
                <h3 className='privacy__h3'>Almacenamiento local</h3>
                <p className='privacy__p'>
                    El almacenamiento local, a veces conocido como almacenamiento DOM, proporciona a las aplicaciones web métodos y protocolos para almacenar datos del lado del cliente. El almacenamiento web admite el almacenamiento persistente de datos, similar a las cookies pero con una capacidad muy superior y sin información almacenada en la cabecera de la petición HTTP.
                </p>
            </div>
        </div>
        <br />
        
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>
                <h3 className='privacy__h3'>Información sobre el Reglamento General de Protección de Datos (GDPR)</h3>
                <p className='privacy__p'>
                    Es posible que recopilemos y utilicemos información sobre usted si es del Espacio Económico Europeo (EEE), y en esta sección de nuestra Política de Privacidad vamos a explicar exactamente cómo y por qué se recopilan estos datos, y cómo mantenemos estos datos bajo protección para que no se reproduzcan o se utilicen de forma incorrecta.
                </p>
            </div>
        </div>
        <br />
        
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>
                <h3 className='privacy__h3'>¿Qué es el GDPR?</h3>
                <p className='privacy__p'>
                        El GDPR es una ley de privacidad y protección de datos a nivel de la UE que regula la protección de los datos de los residentes de la UE por parte de las empresas y mejora el control que tienen los residentes de la UE sobre sus datos personales.
                        <br /> <br />
                        El RGPD es relevante para cualquier empresa que opere en todo el mundo y no sólo para las empresas con sede en la UE y los residentes de la UE. Los datos de nuestros clientes son importantes con independencia de su ubicación, por lo que hemos implantado los controles del RGPD como norma básica para todas nuestras operaciones en todo el mundo.
                </p>
            </div>
        </div>
        <br />
        
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>
                <h3 className='privacy__h3'>¿Qué son los datos personales?</h3>
                <p className='privacy__p'>
                    Cualquier dato que se refiera a una persona identificable o identificada. El RGPD abarca un amplio espectro de información que puede utilizarse por sí sola, o en combinación con otros datos, para identificar a una persona. Los datos personales van más allá del nombre o la dirección de correo electrónico de una persona. Algunos ejemplos son la información financiera, las opiniones políticas, los datos genéticos, los datos biométricos, las direcciones IP, la dirección física, la orientación sexual y el origen étnico.
                    <br /> <br/> 
                    Los Principios de Protección de Datos incluyen requisitos como:
                </p>
                <ul className='privacy__ul'>
                    <li className='privacy__li'>Los datos personales recogidos deben tratarse de forma justa, legal y transparente y sólo deben utilizarse de la forma que una persona esperaría razonablemente.</li>
                    <li className='privacy__li'>Los datos personales sólo deben recogerse para cumplir un propósito específico y sólo deben utilizarse para ese propósito. Las organizaciones deben especificar por qué necesitan los datos personales cuando los recogen.</li>
                    <li className='privacy__li'>Los datos personales no deben conservarse más tiempo del necesario para cumplir su finalidad.</li>
                    <li className='privacy__li'>Las personas cubiertas por el RGPD tienen derecho a acceder a sus propios datos personales. También pueden solicitar una copia de sus datos, y que sus datos sean actualizados, eliminados, restringidos o trasladados a otra organización.</li>
                </ul>
            </div>
        </div>
        <br />
        
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>
                <h3 className='privacy__h3'>¿Por qué es importante el GDPR?</h3>
                <p className='privacy__p'>
                    El RGPD añade algunos requisitos nuevos sobre la forma en que las empresas deben proteger los datos personales de los individuos que recogen y procesan. También eleva las apuestas para el cumplimiento al aumentar la aplicación e imponer mayores multas por incumplimiento. Más allá de estos hechos, es simplemente lo que hay que hacer. En Swapper creemos firmemente que la privacidad de sus datos es muy importante y ya contamos con sólidas prácticas de seguridad y privacidad que van más allá de los requisitos de este nuevo reglamento.
                </p>
            </div>
        </div>
        <br />
        
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>
                <h3 className='privacy__h3'>Derechos del interesado - Acceso a los datos, portabilidad y supresión</h3>
                <p className='privacy__p'>
                    Nos comprometemos a ayudar a nuestros clientes a cumplir con los requisitos de derechos de los sujetos de datos del GDPR. Swapper procesa o almacena todos los datos personales en proveedores totalmente examinados y que cumplen con la DPA. Almacenamos todas las conversaciones y los datos personales durante un máximo de 6 años, a menos que se eliminada su cuenta. En ese caso, eliminamos todos los datos de acuerdo con nuestras condiciones de servicio y política de privacidad, pero no los conservaremos más de 60 días.
                </p>
            </div>
        </div>
        <br />
        
        <div className='row justify-content-center'>
            <div className='col-lg-8 col-10'>

            <h3 className='privacy__h3'>Contacto con nosotros</h3>
            <p className='privacy__p'>
                No dude en ponerse en contacto con nosotros si tiene alguna pregunta. <br /> <br />
                Por correo electrónico: info@swapper.media <br />
                A través de este enlace: www.swapper.media
            </p>
            </div>
        </div>
        <br />
    </div>

    <Footer />
    </div>
  )
}
