import React, { Component } from "react";

class Landing extends Component {
    render() {
        return (
            <div>
                <section className="hero-section" id="hero">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 hero-text-image">
                                <div className="row">
                                    <div className="col-md-8 col-lg-7 col-xl-7 text-left text-lg-left mt-2 pt-2 mt-lg-3 pt-lg-3 mt-xl-5 pt-xl-5">
                                        <h1 data-aos="fade-right text-left" className="nunito pt-5 pt-md-0">La conexión perfecta con tu audiencia.</h1>
                                        <p className="mb-5" data-aos="fade-right" data-aos-delay="100">Swapper es la plataforma donde los usuarios podrán participar por premios al escuchar sus radios o canales de TV favoritos. Los medios, artistas y marcas aliados recibirán en su panel de estadísticas  las métricas claves acerca del alcance de su contenido.</p>
                                    </div>
                                    <div className="d-lg-block d-none  d-md-block offset-md-0 col-md-4 col-lg-4 offset-lg-1 offset-xl-1 col-xl-4 iphone-wrap pt-2 pt-xl-2">
                                        <img className="w-100 pt-md-0 pt-xl-4" src="img/swapper/celular.png" alt="phone" data-aos="fade-right" data-aos-delay="200" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        );
    }
}

export default Landing;