import React from 'react';
import Header from '../header/Header';
import Footer from '../landing/footer';
import axios from "axios";

import './Profile.css';
class Profile extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            loading:true,
            error: null,
            data: "",    
            errors: {
                passwordError: ''
            },
            messageResponse: "",
            form: {
                actualPassword: '',
                newPassword: '',
                repeatPassword: '',
                showPassword: false,

            },
            hidden: true,
            showPassworActualdInput: true,
            hidePassworActualdInput: false,
            showNewPassworActualdInput: true,
            hideNewPassworActualdInput: false,
            showNewRPassworActualdInput: true,
            hideNewRPassworActualdInput: false,
            moreminus: "+"
            
        }

        this.validate = this.validate.bind(this);

        this.cambioContraseña = React.createRef();
        this.cambioContraseñaExitoso = React.createRef();
        this.showContraseña = this.showContraseña.bind(this);
        this.loadUserInfo();
    }

    async loadUserInfo(){
        this.setState({loading: true});

        const SERVER_URL = `https://swapper.media/public/app`;
        const config = {
            headers: { Authorization: `Bearer `+localStorage.getItem('accessToken')}
        };
        
        const res = await axios.get(SERVER_URL + "/api/partner/userinfo?email="+encodeURIComponent(localStorage.getItem('emailPartner')), config)
        const data = res.data.userdata;
        this.setState({data:data, loading: false});

    }

    componentDidMount(){
        this.loadUserInfo();
    }

    handleSubmit = event => {
        event.preventDefault();
        if(this.validate()){
      
            let errors = {};

            const SERVER_URL = `https://swapper.media/public/app`;
            const config = {
                headers: { Authorization: `Bearer `+localStorage.getItem('accessToken')}
            };
            axios
            .post(SERVER_URL + "/api/partner/changePassword", {
                email : localStorage.getItem('emailPartner'),
                password :event.target[0].value,
                newpassword : event.target[1].value,
            }, config)
            .then(response => {
                if(response.data.code === 200){
                    this.setState({ messageResponse: "Mensaje enviado con éxito"});
                    let form = {};
                    form["actualPassword"] = "";
                    form["newPassword"] = "";
                    form["repeatPassword"] = "";
                    this.setState({form:form});
                    this.showContraseña();
                    this.showCambioExitoso();
                }
            })
            .catch(error => {
                console.log(error);
                errors["passwordError"] = 'La contraseña actual no es correcta';
                this.setState({
                    errors: errors
                  });
            });
            
            
        }
    }
    handleChange = event => {
        let form = this.state.form;
        form[event.target.name] = event.target.value;
      
        this.setState({
          form
        });

    }
    validate(){
        let form = this.state.form;
        let errors = {};
        let isValid = true;
        const passwordRegex = new RegExp("^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z]).{8,}$");
        if (!form["actualPassword"]) {
          isValid = false;
          errors["passwordError"] = "Campo de contraseña actual requerido.";
        }
    
        if (!form["newPassword"]) {
          isValid = false;
          errors["passwordError"] = "Campo de nueva contraseña requerido.";
        }

        if (!form["repeatPassword"]) {
          isValid = false;
          errors["passwordError"] = "Campo de repetir contraseña requerido.";
        }

        if(form["actualPassword"] === form["newPassword"]){
            isValid = false;
            errors["passwordError"] = "La contraseña actual y la nueva contraseña no deben ser las mismas.";

        }
        
        if(form["repeatPassword"] !== form["newPassword"]){
            isValid = false;
            errors["passwordError"] = "Por favor, repita correctamente la nueva contraseña.";
        }

        if(!passwordRegex.test(form["newPassword"])){
            isValid = false;
            if(!RegExp("^(?=.*[0-9].*[0-9])").test(form["newPassword"])){
                console.log('Entró 1');
                errors["passwordError"] = "La contraseña no contiene al menos 2 números";   

            }else if(!RegExp("^(?=.*[!@#$&*])").test(form["newPassword"])){
                console.log('Entro 2')
                errors["passwordError"] = "La contraseña no posee ningún caracter no alfanumérico";   

            }else if(!RegExp("^(?=.*[A-Z])").test(form["newPassword"])){
                errors["passwordError"] = "La contraseña no posee ninguna letra mayúscula";   

            }else if(!RegExp("^.{8,}").test(form["newPassword"])){
                errors["passwordError"] = "La contraseña debe tener al menos 8 caracteres";   

            }else{
                errors["passwordError"] = "";
            }
        }

        this.setState({
          errors: errors
        });
    
        return isValid;
    }
    Capitalize(str){
        if(str == null){
            return str
        }else{

            return str.charAt(0).toUpperCase() + str.slice(1);
        }
    }
    showContraseña(){
        if(!this.changePasswordSucces){

            if(this.showChangePassword){
                
                this.cambioContraseña.current.style.display = 'none';
                this.showChangePassword = false;
                this.setState({ moreminus : "+"});
            }else{
                this.cambioContraseña.current.style.display = 'block';
                this.showChangePassword = true;
                this.setState({ moreminus : "-"});
            }
        }
    }
    showCambioExitoso(){
            
        this.cambioContraseñaExitoso.current.style.display = 'inline-block';
        this.changePasswordSucces = true;
        
    }
    showPassworActualdInput(){
        this.setState({ showPassworActualdInput : true});
        this.setState({ hidePassworActualdInput : false});
        
    }
    hidePassworActualdInput(){
        this.setState({ showPassworActualdInput : false});
        this.setState({ hidePassworActualdInput : true});
    }
    showNewPassworActualdInput(){
        this.setState({ showNewPassworActualdInput : true});
        this.setState({ hideNewPassworActualdInput : false});
        
    }
    hideNewPassworActualdInput(){

        this.setState({ showNewPassworActualdInput : false});
        this.setState({ hideNewPassworActualdInput : true});
    }
    showNewRPassworActualdInput(){
        this.setState({ showNewRPassworActualdInput : true});
        this.setState({ hideNewRPassworActualdInput : false});
        
    }
    hideNewRPassworActualdInput(){

        this.setState({ showNewRPassworActualdInput : false});
        this.setState({ hideNewRPassworActualdInput : true});
    }
    render(){
        const loading = this.state.loading;
        return(
            <div>
                <Header location="profile"/>

            {loading ? 
            (
                <div className="min-size row justify-content-center align-items-center">
                    <img alt="loader" src="../img/swapper/loader.gif" />
                </div>
            ) : (
                <div className="container container-girl margin-top-80-px">
                    <div className="row justify-content-center img-girl">

                        <div className="col-md-6 col-lg-4 d-none d-md-block" id="epa">

                            <img className="imagenGirl" src="./img/swapper/mujer-sinfondo-flip.png" alt="Mujer Swapper" />
                        </div>
                        <div className="col-md-5 col-lg-6 align-self-stretch">
                                <div className="info">
                                    <h1 className="text-sm-center text-md-right">¡Hola, {this.Capitalize(this.state.data.name)} {this.Capitalize(this.state.data.lastname)}!</h1>
                                    <p className="text-sm-center text-md-right">{this.Capitalize(this.state.data.name)} {this.Capitalize(this.state.data.lastname)}</p>
                                    <p className="text-sm-center text-md-right">{localStorage.getItem('emailPartner')}</p>
                                    <p className="text-sm-center text-md-right">Número de teléfono</p>
                                    <p className="text-sm-center text-md-right">{this.state.data.phone}</p>
                                    <div className="cambioContraseña" >
                                        {/* <p className="text-sm-center text-md-right" id="icon">+</p> */}
                                        <p className="font-weight-bold text-sm-center text-md-right strong color-black cambio-contraseña-cursor" onClick={this.showContraseña}><span className='buttonMore'>{this.state.moreminus}</span>Cambiar contraseña</p>

                                    </div>
                                    <div ref={this.cambioContraseña} id="cambioContraseña">
                                        {/* <p className="font-weight-bold text-sm-center text-md-right strong color-black">Cambiar contraseña</p> */}
                                        <br/>
                                        <p className="font-weight-bold text-sm-center text-md-right strong color-black cambio-contraseña-font-size">Requerimientos:</p>
                                        <p className="text-sm-center text-md-right cambio-contraseña-font-size">Mínimo 8 caracteres, mínimo 1 no alfanumérico.</p>
                                        <p className="text-sm-center text-md-right cambio-contraseña-font-size">Mínimo 2 caracteres deben ser numéricos.</p>
                                        <p className="text-sm-center text-md-right cambio-contraseña-font-size">Se permiten caracteres en mayúsculas y especiales.</p>
                                        <div id="formularioNuevaContraseña" className="pt-3">
                                            <form name='nuevaContraseña' id='nuevaContraseña' method='POST' onSubmit={this.handleSubmit}>
                                                <div>
                                                    
                                                    <div className='row justify-content-center justify-content-md-end mb-2 pr-0'>

                                                        <input placeholder='Contraseña actual'
                                                            name='actualPassword'
                                                            onBlur={this.handleChange}
                                                            onChange={this.handleChange}
                                                            type={this.state.hidePassworActualdInput ? "text": "password"}
                                                            id='actualPassword'
                                                            className='form-control col-8 col-md-6  text-center text-md-right'
                                                        />
                                                        <div className="ShowPasswordActual">
                                                        { this.state.showPassworActualdInput ? (
                                                            <i onClick={this.hidePassworActualdInput.bind(this)} className="fas fa-eye"></i>
                                                        ): null } 
                                                        
                                                        { this.state.hidePassworActualdInput ? (
                                                            <i onClick={this.showPassworActualdInput.bind(this)} className="far fa-eye-slash"></i>
                                                            ): null }
                                                        </div>
                                                    </div>
                                                    <div className='row justify-content-center justify-content-md-end mb-2 pr-0'>
                                                        <input placeholder='Nueva contraseña'
                                                            name='newPassword'
                                                            onBlur={this.handleChange}
                                                            onChange={this.handleChange}
                                                            id='newPassword'
                                                            className='form-control col-8 col-md-6 text-center text-md-right'
                                                            type={this.state.hideNewPassworActualdInput ? "text": "password"}

                                                        />

                                                        <div className="ShowNewPasswordActual">
                                                        { this.state.showNewPassworActualdInput ? (
                                                            <i onClick={this.hideNewPassworActualdInput.bind(this)} className="fas fa-eye"></i>
                                                        ): null } 
                                                        
                                                        { this.state.hideNewPassworActualdInput ? (
                                                            <i onClick={this.showNewPassworActualdInput.bind(this)} className="far fa-eye-slash"></i>
                                                            ): null }
                                                        </div>

                                                    </div>
                                                    <div className='row justify-content-center justify-content-md-end mb-2 pr-0'>
                                                        <input placeholder='Repita la contraseña'
                                                            name='repeatPassword'
                                                            onBlur={this.handleChange}
                                                            onChange={this.handleChange}
                                                            id='repeatPassword' 
                                                            className='form-control col-8 col-md-6 text-center text-md-right'
                                                            type={this.state.hideNewRPassworActualdInput ? "text": "password"}

                                                        />

                                                        <div className="ShowNewPasswordActual">
                                                        { this.state.showNewRPassworActualdInput ? (
                                                            <i onClick={this.hideNewRPassworActualdInput.bind(this)} className="fas fa-eye"></i>
                                                        ): null } 
                                                        
                                                        { this.state.hideNewRPassworActualdInput ? (
                                                            <i onClick={this.showNewRPassworActualdInput.bind(this)} className="far fa-eye-slash"></i>
                                                            ): null }
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="row justify-content-end col-12">
                                                    <div className="cambio-contraseña-font-size cambio-contraseña-danger-color col-md-6 p-0 my-3 justify-text">{this.state.errors.passwordError}</div>
                                                </div>
                                                <div className='row justify-content-center justify-content-sm-center justify-content-md-end col-12 mx-0'>
                                                    <button type='Submit' className='btnSaveChanges shadow-none align-center btn btn-success'>Guardar cambios</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div ref={this.cambioContraseñaExitoso} id="cambioContraseñaExitoso" className="col-12 px-0">
                                        <div className="row justify-content-end"> 

                                            <div className="col-md-6 col-lg-4 px-0">

                                            <img src="./img/swapper/check.svg" alt="Contraseña cambiada exitosamente" className="col-3 col-md-6 col-lg-4 pt-3 pb-2"/>
                                            <p className="pb-4 pb-md-0">Su contraseña ha sido cambiada exitosamente</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>

                    </div>
                    <div className="row justify-content-center .d-block .d-md-none">
                        <div className="d-block d-md-none" id="epa">
                            
                            <img src="./img/swapper/mujer-sinfondo.png" alt="Mujer Swapper" />
                        </div>
                    </div>
                </div>
            )}
                <Footer>
                </Footer>
            </div>
        );
    }
}

export default Profile;

